import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { DatePicker } from '@material-ui/pickers'
import moment from 'moment'
import {
    Card, CardHeader, CardContent, CardActions, Divider,
    Grid, Button, IconButton, Typography, TextField,
    colors, CircularProgress, useMediaQuery,
} from '@material-ui/core'
import { Page } from 'components'
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded'
import NavigateBeforeRoundedIcon from '@material-ui/icons/NavigateBeforeRounded';
import EventNoteRoundedIcon from '@material-ui/icons/EventNoteRounded';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import { makeStyles } from '@material-ui/styles'
import { AccessControl } from "components/AccessControl/AccessControl";
import { PERMISSIONS } from "common/permissions";

import { MothCalendar, WeekCalendar } from './components'
import { useSelector, useDispatch } from 'react-redux'
import Jobs from './components/Jobs'
import apiConfig from "../../apiConfig";
import axios from "../../utils/axios";
import localStorage from "../../utils/localStorage";
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import NumberFormat from 'react-number-format';
const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    circularProgress: {
        marginTop: theme.spacing(3)
    },
    groupDistance: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center'
        }
    },
    button: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    navigateBefore: {
        padding: 6,
        marginRight: 6
    },
    navigateNext: {
        padding: 6,
        marginLeft: 6
    },
    dateTitle: {
        fontSize: theme.spacing(2),
        fontWeight: 500,
        [theme.breakpoints.down('xs')]: {
            fontSize: 14,
            whiteSpace: 'nowrap'
        }
    },
    dateBox: {
        position: 'relative',
        textAlign: 'center'
    },
    dateBoxIcon: {
        position: 'absolute',
        top: 3,
        right: 0,
        padding: 12
    },
    typeSelected: {
        '& div::before': {
            display: 'none'
        },
        '& div::after': {
            display: 'none'
        },
        '& div select.MuiSelect-select.MuiSelect-select': {
            paddingLeft: '10px',
            paddingRight: '10px'
        },
    },
    groupSearch: {
        width: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center'
    },
    inputSearch: {
        '& div input': {
            padding: '14px'
        }
    },
    iconSearch: {
        padding: '10px',
        transform: 'rotate(90deg)',
        position: 'absolute',
        right: 0,
    },
    inputColor: {
        width: 55,
        '& div input': {
            padding: 10
        }
    },
    colorBox: {
        borderRadius: 4,
        width: 10,
        height: 36,
        display: 'inline-block',
        marginLeft: 8,
        marginRight: 4
    },
    calendarContent: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            height: 'calc(100vh - 297px)'
        },
        [theme.breakpoints.up('md')]: {
            height: 'calc(100vh - 252px)'
        }
    }
}))

const Schedules = props => {
    const history = useHistory();
    const sm = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const classes = useStyles();
    const dispatch = useDispatch();
    const { selectDate } = useParams();
    const { viewType, index, distances } = useSelector(state => state.SchedulesReducer);
    const arrDate = selectDate ? selectDate.split('-') : null

    const [date, setDate] = useState((arrDate ? new Date(parseInt(arrDate[0]), parseInt(arrDate[1]) - 1, parseInt(arrDate[2])) : new Date()));
    const [days, setDays] = useState(null);
    const [indexs, setIndexs] = useState(null);
    const [contact, setContacts] = useState(null);
    const [selectedJob, setSelectedJob] = useState(null);

    const [openPicker, setOpenPicker] = useState(false);

    const [colorInput, setColorInput] = useState({GreenMiles: '', YellowMiles: '', RedMiles: ''});

    const onChangeViewType = (event, viewType) => {
        if (viewType ?? event.target.value)
            dispatch({ type: 'CHANGE_VIEW_TYPE', viewType: viewType ?? event.target.value })
    }

    const redirectToRoutesSchedules = (dayString) => {
        history.push(`/schedules/routes/${dayString}`, {jobId: selectedJob ? selectedJob : null})
    }

    const onPrev = () => {
        switch (viewType) {
            case 'month':
                const prevMonth = new Date(date.setDate('1')).setMonth(date.getMonth() - 1);
                setDate(new Date(prevMonth));
                dispatch({ type: 'CHANGE_WEEK', index: 0 })
                break;
            case 'week':
                const inx = index - 1;
                if (inx < 0) {
                    const prevMonth = new Date(date.setDate('1')).setMonth(date.getMonth() - 1);
                    const prevDate = new Date(prevMonth);
                    const numberDays = getNumberDaysOfMonth(prevDate.getFullYear(), prevDate.getMonth());
                    setDate(new Date(prevMonth));
                    dispatch({ type: 'CHANGE_WEEK', index: numberDays / 7 - 2 })
                } else {
                    dispatch({ type: 'CHANGE_WEEK', index: inx })
                }
                break;
            default:
                break;
        }
    }
    const onNext = () => {
        switch (viewType) {
            case 'month':
                const nextMonth = moment(date).add(1, 'month').toDate();
                setDate(nextMonth);
                dispatch({ type: 'CHANGE_WEEK', index: 0 })
                break;
            case 'week':
                const inx = index + 1;
                if (inx > indexs.length - 1) {
                    const nextMonth = moment(date).add(1, 'month').toDate();
                    setDate(nextMonth);
                    dispatch({ type: 'CHANGE_WEEK', index: 1 })
                } else {
                    dispatch({ type: 'CHANGE_WEEK', index: inx })
                }
                break;
            default:
                break;
        }
    }
    const onChangeMonth = date => {
        setDate(new Date(date));
        dispatch({ type: 'CHANGE_WEEK', index: 0 })
    }
    const getNumberDaysOfMonth = (year, month) => {
        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(year, month + 1, 0);

        const prevDays = firstDay.getDay();
        const nextDays = 6 - lastDay.getDay();

        return prevDays + nextDays + moment(firstDay).daysInMonth();
    }
    const getDaysOfPrevMonth = (month, year) => {
        const date = new Date(year, month, 1);
        let prevDays = date.getDay();
        let lastDay = new Date(year, month, 0)
        lastDay.setDate(lastDay.getDate() - (prevDays - 1))
        const daysOfPrevMonth = [];
        while (prevDays > 0) {
            // get days of prev month
            daysOfPrevMonth.push(new Date(lastDay));
            lastDay.setDate(lastDay.getDate() + 1)
            prevDays--
        }
        return daysOfPrevMonth;
    }
    const getDaysOfMonth = (month, year) => {
        let date = new Date(year, month, 1);

        const daysOfMonth = [];
        while (date.getMonth() === month) {
            // get day of current month
            daysOfMonth.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }
        date.setDate(date.getDate() + 1);

        return daysOfMonth;
    }
    const getDaysOfNextMonth = (month, year) => {
        const date = new Date(year, month + 1, 0);
        let nextDays = 6 - date.getDay();
        let firstDay = new Date(year, month + 1, 1)
        const daysOfNextMonth = [];

        while (nextDays > 0) {
            //get days of next month
            daysOfNextMonth.push(new Date(firstDay));
            firstDay.setDate(firstDay.getDate() + 1);
            nextDays--
        }

        return daysOfNextMonth;
    }
    const getMonthOfCalendar = (month, year) => {
        const daysOfPrevMonth = getDaysOfPrevMonth(month, year);
        const daysOfMonth = getDaysOfMonth(month, year);
        const daysOfNextMonth = getDaysOfNextMonth(month, year);
        const days = daysOfPrevMonth.concat(daysOfMonth).concat(daysOfNextMonth);
        const indexs = [];

        for (let index = 0; index < (days.length / 7); index++) {
            indexs.push(index);
        }

        if (new Date().getMonth() === month && new Date().getFullYear() === year) {
            const today = new Date(year, month, new Date().getDate())
            const indexToday = days.map(Number).indexOf(+today);
            dispatch({ type: 'CHANGE_WEEK', index: Math.ceil((indexToday + 1) / 7) - 1 })
        }

        setDays(days);
        setIndexs(indexs);

    }
    const stringWeek = (index, days) => {
        const week = days.slice(7 * index, 7 + (7 * index));
        const startDate = week[0];
        const endDate = week[6];

        if (startDate.getFullYear() === endDate.getFullYear()) {
            if (startDate.getMonth() === endDate.getMonth()) {
                return `${moment(startDate).format('MMM')} ${startDate.getDate()} - ${endDate.getDate()}, ${moment(startDate).format('YYYY')}`
            }
            return `${moment(startDate).format('MMM')} ${startDate.getDate()} - ${moment(endDate).format('MMM')} ${endDate.getDate()}, ${moment(startDate).format('YYYY')}`
        }
        return `${moment(startDate).format('MMM')} ${startDate.getDate()}, ${moment(startDate).format('YYYY')} - ${moment(endDate).format('MMM')} ${endDate.getDate()}, ${moment(endDate).format('YYYY')}`
    }

    const directTodayRoute = () => {
        const curDate = new Date();
        setDate(curDate);
        dispatch({ type: 'CHANGE_DATE', date: moment(curDate).format('MM/DD/YYYY') });
    }

    useEffect(() => {
        const companyId = localStorage.getCompanyId();
        let url = apiConfig.url.BASE_URL + apiConfig.url.COMPANY_INFO + companyId;
        axios.get(url)
            .then((res) => {
                setColorInput({
                    GreenMiles: res.data.greenMiles,
                    YellowMiles: res.data.yellowMiles,
                    RedMiles: res.data.redMiles
                });
            })
            .finally()
    }, [])

    useEffect(() => {
        getMonthOfCalendar(date.getMonth(), date.getFullYear());
    }, [date])

    if (!days || !indexs) {
        return (
            <Page title='Route Schedule'>
                <Grid
                    container
                    spacing={0}
                    align="center"
                    justifyContent="center"
                    direction="column">
                    <Grid item>
                        <CircularProgress className={classes.circularProgress} size={50} />
                    </Grid>
                </Grid>
            </Page>
        )
    }

    return (
        <AccessControl requiredPermission={PERMISSIONS.page.schedules} redirect>
            <Page title='Route Schedule'>
                <div className={classes.root}>
                    <Card>
                        <CardHeader title="Schedules" />
                        <Divider />
                        <CardActions>
                            <Grid container justifyContent={sm ? 'center' : 'space-between'} alignItems="center">
                                <Grid item>
                                    <Button
                                        className={classes.button}
                                        onClick={directTodayRoute}
                                        color='primary'
                                        variant='contained'>
                                        Today
                                    </Button>
                                </Grid>
                                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                                    <IconButton
                                        className={classes.navigateBefore}
                                        onClick={onPrev}>
                                        <NavigateBeforeRoundedIcon />
                                    </IconButton>
                                    <div
                                        className={classes.dateBox}
                                        style={{ minWidth: viewType === 'month' ? 160 : 224 }}
                                        onClick={() => setOpenPicker(true)}>
                                        <TextField
                                            fullWidth
                                            InputProps={{
                                                readOnly: true
                                            }}
                                            value={viewType === 'month' ? moment(date).format('MMMM YYYY') : stringWeek(index, days)}
                                            variant="outlined" />
                                        <IconButton className={classes.dateBoxIcon}>
                                            <EventNoteRoundedIcon />
                                        </IconButton>
                                    </div>
                                    <IconButton
                                        className={classes.navigateNext}
                                        onClick={onNext}>
                                        <NavigateNextRoundedIcon />
                                    </IconButton>
                                </Grid>
                                {distances && distances.length > 0 &&
                                <>
                                    {sm && <div style={{ flexBasis: '100%', height: 6 }}></div>}
                                    <Grid item>
                                        <div className={classes.groupDistance}>
                                            <span>Distance (miles)</span>
                                            <div className={classes.colorBox} style={{backgroundColor: '#43a047'}}></div>
                                            <span>{" < "+colorInput.YellowMiles}</span>
                                            <div className={classes.colorBox} style={{backgroundColor: '#F5D71D'}}></div>
                                            <NumberFormat
                                                customInput={TextField}
                                                value={colorInput.YellowMiles}
                                                className={classes.inputColor}
                                                fullWidth
                                                name='green_color'
                                                variant='outlined'
                                                placeholder='Miles'
                                                onChange={(e) => {
                                                    setColorInput({
                                                        ...colorInput,
                                                        YellowMiles: e.target.value
                                                    })
                                                }}
                                            />
                                            <div className={classes.colorBox} style={{backgroundColor: '#F72A0F'}}></div>
                                            <NumberFormat
                                                customInput={TextField}
                                                value={colorInput.RedMiles}
                                                className={classes.inputColor}
                                                fullWidth
                                                name='green_color'
                                                variant='outlined'
                                                placeholder='Miles'
                                                onChange={(e) => setColorInput({
                                                    ...colorInput,
                                                    RedMiles: e.target.value
                                                })}
                                            />
                                        </div>
                                    </Grid>
                                </>
                                }
                                {sm && <div style={{ flexBasis: '100%', height: 6 }}></div>}
                                <Grid item>
                                    {
                                        sm
                                            ? (
                                                <ToggleButtonGroup
                                                    color="primary"
                                                    size="small"
                                                    value={viewType}
                                                    exclusive
                                                    onChange={onChangeViewType}
                                                    aria-label="View Type"
                                                >
                                                    <ToggleButton value="month">Month</ToggleButton>
                                                    <ToggleButton value="week">Week</ToggleButton>
                                                </ToggleButtonGroup>
                                            )
                                            : (
                                                <TextField
                                                    className={classes.typeSelected}
                                                    name='type'
                                                    variant='standard'
                                                    select
                                                    onChange={onChangeViewType}
                                                    SelectProps={{ native: true, style: {width: '70px'} }}
                                                    value={viewType}>
                                                    <option value='month'>Month</option>
                                                    <option value='week'>Week</option>
                                                </TextField>
                                            )
                                    }
                                    {/*<div className={classes.groupSearch}>*/}
                                    {/*    <TextField*/}
                                    {/*        className={classes.inputSearch}*/}
                                    {/*        fullWidth*/}
                                    {/*        name='search'*/}
                                    {/*        variant='outlined'*/}
                                    {/*        placeholder='Search Calendar'*/}
                                    {/*    />*/}
                                    {/*    <IconButton className={classes.iconSearch}>*/}
                                    {/*        <SearchRoundedIcon />*/}
                                    {/*    </IconButton>*/}
                                    {/*</div>*/}
                                </Grid>
                            </Grid>
                        </CardActions>
                        <Divider />
                        <CardContent style={{ padding: 0 }}>
                            <div className={classes.calendarContent}>
                                <Jobs date={date} selectedJob={selectedJob} setSelectedJob={setSelectedJob}/>
                                <div style={{ overflow: 'auto', flex: 'auto' }}>
                                    {viewType === 'week'
                                        ? <WeekCalendar
                                            contact={colorInput}
                                            index={index}
                                            days={days}
                                            date={date}
                                            redirectToRoutesSchedules={redirectToRoutesSchedules}
                                        />
                                        : <MothCalendar
                                            contact={colorInput}
                                            indexs={indexs}
                                            days={days}
                                            date={date}
                                            redirectToRoutesSchedules={redirectToRoutesSchedules}
                                        />
                                    }
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    <DatePicker
                        style={{ display: 'none' }}
                        views={["month"]}
                        open={openPicker}
                        onClose={() => setOpenPicker(false)}
                        onChange={date => onChangeMonth(date)}
                        variant='dialog'
                        value={date ? date : new Date()}
                    />
                </div>
            </Page>
        </AccessControl>
    )
}

export default Schedules;
