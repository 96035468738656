import React, {useState,useEffect} from 'react'
import {Typography, TextField, Button, Grid, colors,CircularProgress} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ModalShowHistory from "./ModalShowHistory";
import ModalWorkOrder from './ModalWorkOrder';
import EmailModal from "../../CustomerDetails/components/JobAddresses/components/EmailModal";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import axios from "utils/axios";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import moment from "moment";
import apiConfig from "apiConfig";
import { AddressLink } from 'components';

const useStyles = makeStyles(theme => ({
    address: { marginBottom: 14 },
    contact: { marginBottom: 14 },
    typo: { fontWeight: 500 },
    btn: {
        whiteSpace: 'nowrap',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
        fontSize: 12,
        '&:disabled': {
            whiteSpace: 'nowrap',
            color: 'rgba(0, 0, 0, 0.26)',
            backgroundColor: 'rgb(217 217 217)',
            pointerEvents: 'none'
        }
    },
    addressLink: {
        textDecoration: 'underline',
        color:'#0000EE'
    }
}))

const JobCommon = ({job,isEnableTodayRoute}) => {
    const classes = useStyles();
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [openModal, setOpenModal] = useState(false);
    const [openModalWorkOrder, setOpenModalWorkOrder] = useState(false);
    const [openEmailModal, setOpenEmailModal] = useState(false);
    const [invoiceGenerated, setInvoiceGenerated] = useState(false);
    const md_down = useMediaQuery('(max-width: 961px)');
    const xs_down = useMediaQuery('(max-width: 500px)');
    const [postingInvoiceSent, setPostingInvoiceSent] = useState(false);
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [dateSent, setDateSent] = useState(null);
    const [invoice, setInvoice] = useState();
    const markInvoiceSent = () => {
        setPostingInvoiceSent(true);
        axios.patch(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_MARK_INVOICE_SENT, { jobId: job.jobId, dateSent: new Date() })
            .then(res => {
                setIsStatus({ failed: false, msg: 'Mark invoice sent successfully.' });
                setOpenSnackBar(true);
                setDateSent(moment());
            })
            .catch(err => {
                setIsStatus({ failed: true, msg: 'Mark invoice sent failed.' });
                setOpenSnackBar(true);
            })
            .finally(() => setPostingInvoiceSent(false));
    }
    const getInvoice = () => {
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_INVOICE, { params: { jobId: job.jobId } })
            .then(res => {
                if (res.data) {
                    setInvoice(res.data);
                    if (res.data.invoice?.dateSent) setDateSent(res.data.invoice?.dateSent);
                }
            })
    }
    useEffect(() => {
        if(job)
           getInvoice();
    }, [])
    return (
        <>
            <Grid container>
                <Grid item xs={5}>
                    <div className={classes.address}>
                        <Typography className={classes.typo}>{job.jobsiteContactName}</Typography>
                        <AddressLink
                            address={{
                                address1: job.jobsiteAddress1,
                                city: job.jobsiteCity,
                                state: { name: job.jobsiteState },
                                zipCode: job.jobsiteZipCode
                            }}
                            className={classes.typo}
                        />
                    </div>
                    <div className={classes.contact}>
                        <Typography className={classes.typo}>{job.jobSitePhoneNumber}</Typography>
                        <Typography className={classes.typo}>{job.jobsiteEmailAddress}</Typography>
                    </div>
                </Grid>
                <Grid item xs={7}>
                    <div style={{display: 'flex', justifyContent: 'flex-end', paddingRight: md_down ? null : 24, width: xs_down ? '100%' : null, flexDirection: xs_down ? 'column' : 'row'}}>
                        <Button
                            className={classes.btn}
                            disabled={!job.jobId}
                            variant={"contained"}
                            style={{ marginRight: xs_down ? null : 8, marginBottom: xs_down ? 8 : null }}
                            onClick={() => setOpenEmailModal(true)}>
                            Quote/Invoice
                        </Button>
                        <Button
                            disabled={(!invoice || !invoice.invoice) && !invoiceGenerated || dateSent}
                            style={{ marginRight: xs_down ? null : 8, marginBottom: xs_down ? 8 : null }}
                            className={classes.btn}
                            onClick={() => {
                                markInvoiceSent();
                            }}
                        >Mark Invoice Sent</Button>
                        {postingInvoiceSent && <CircularProgress size={24} style={{alignSelf: 'center'}} />}
                        <Button style={{ whiteSpace: 'nowrap' }}
                                className={classes.btn}
                                variant={"contained"}
                                onClick={() => setOpenModal(true)}>
                            View Job History
                        </Button>
                    </div>
                    <br/>
                    <div>
                        <Button style={{ whiteSpace: 'nowrap' }}
                                className={classes.btn}
                                onClick={() => setOpenModalWorkOrder(true)}>
                            Sign Work Order
                        </Button>
                    </div>
                </Grid>
            </Grid>

            {
                (isEnableTodayRoute == null || !isEnableTodayRoute) && (
                    <>
                <TextField
                    style={{ marginBottom: 14 }}
                    fullWidth
                    disabled
                    label='Job Description'
                    variant='outlined'
                    multiline
                    rows={8}
                    value={job.jobDescription}
                />
                <TextField
                    fullWidth
                    disabled
                    label='Split Description'
                    variant='outlined'
                    multiline
                    rows={8}
                    value={job.jobSplitDescription}
                />
                </>)
            }
            <ModalShowHistory
                job={job}
                open={openModal}
                onClose={() => setOpenModal(false)}
            />
            <ModalWorkOrder
                setTitle={() => { }}
                onCloseModal={() => setOpenModalWorkOrder(false)}
                open={openModalWorkOrder}
                jobId={job.jobId}
            />

            <EmailModal
                    setTitle={() => { }}
                    onCloseModal={() => setOpenEmailModal(false)}
                    open={openEmailModal}
                    jobId={job.jobId}
                    onGenerateInvoice={() => setInvoiceGenerated(true)}
            />
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackBar(false)}>
                <Alert
                    elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    )
}

export default JobCommon;
