import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import {
    TableRow,
    TableCell,
    IconButton,
    TextField,
    FormControlLabel,
    Tooltip,
    InputAdornment, Grid,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import DeleteIcon from '@material-ui/icons/Delete';
import { GreenCheckbox } from 'components'
import { Autocomplete } from "@material-ui/lab";
import { DragHandle } from '@material-ui/icons'
import { SortableHandle } from 'react-sortable-hoc'

const useStyles = makeStyles(theme => ({
    bodyCell: {
        borderBottom: 'none',
        verticalAlign: 'top'
    },
    taxable: {
        margin: '0'
    },
    btnDis: {
        whiteSpace: 'nowrap',
        color: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'rgb(217 217 217)',
        pointerEvents: 'none'
    },
    grabbable: {
        cursor: 'move',
        cursor: 'grab',
        cursor: '-moz-grab',
        cursor: '-webkit-grab'
    }
}))

const PriceLine = props => {
    const classes = useStyles();
    const { item, onChangeItem, onChangeDesc, onCopyItem, onRemoveItem, onDirty, lineItem, index, disabled, length, errors, touched ,indexItem, postedToQuickbooks } = props;
    const { dirty, quantity, description, price, taxable, upsale, companyPriceItemId } = item;
    const [value, setValue] = useState({ title: description, price: '', companyPriceItemId: null });
    const [lineItemFiltered, setLineItemFiltered] = useState([...lineItem]);
    const [pr, setPr] = useState('')
    const [change, setChange] = useState(false)
    const [itemId, setItemId] = useState(companyPriceItemId)
    const [number, setNumber] = useState('');
    const [errorLength, setErrorLength] = useState(false)
    let total = parseFloat(quantity * price).toFixed(2);
    const [keyTab, setKeyTab] = useState(false);
    const DragIcon = SortableHandle(() => <span className={classes.grabbable}><i class="fas fa-grip-vertical"></i></span>)
    useEffect(() => {
        setNumber(price)
        setValue({ title: description, price: '', companyPriceItemId: null })
        setPr(price ? parseFloat(price).toFixed(2) : '')
    }, [price])
    useEffect(() => {
        setValue({ title: item.description, price: item.price, companyPriceItemId: item.companyPriceItemId })
        if (!dirty && (!!quantity || !!description || !!price)) onDirty(true);
        else if (dirty && !quantity && !description && !price) onDirty(false);
    }, [item])
    useEffect(() => {
        const focus  = document.getElementById('focusQuantity');
        if(focus) focus.focus()
    }, [])
    return (
        <TableRow key={index}>
            <TableCell className={classes.bodyCell} align='center' style={{verticalAlign:'middle'}}>
                <DragIcon />
            </TableCell>
            <TableCell className={classes.bodyCell}>
                <NumberFormat
                    id={index === length && !item.id ? 'focusQuantity' : ''}
                    customInput={TextField}
                    allowNegative={false}
                    fullWidth
                    disabled={disabled}
                    name='quantity'
                    variant='outlined'
                    value={quantity || ''}
                    onChange={onChangeItem}
                    error={
                        errors.jobItems &&
                        errors.jobItems[indexItem] &&
                        errors.jobItems[indexItem].quantity &&
                        touched.jobItems &&
                        touched.jobItems[indexItem]
                    }
                    helperText={
                        (
                            errors.jobItems &&
                            errors.jobItems[indexItem] &&
                            errors.jobItems[indexItem].quantity &&
                            touched.jobItems &&
                            touched.jobItems[indexItem]
                        )
                        &&
                        errors.jobItems[indexItem].quantity
                    }
                />
            </TableCell>
            <TableCell className={classes.bodyCell}>
                <Autocomplete
                    disabled={disabled}
                    freeSolo
                    autoHighlight={true}
                    autoSelect={true}
                    value={value}
                    disableClearable
                    options={lineItemFiltered}
                    getOptionLabel={(option) => option ? option.title || "" : ""}
                    // filterSelectedOptions
                    onChange={(e, value, q) => {
                        if(!keyTab && q === "blur"){
                            return false;
                        }
                        if (typeof (value) === 'object') {
                            setPr(parseFloat(value.price));
                            setValue(value);
                            setChange(false);
                            setItemId(false);
                            onChangeDesc(value.title, value.price, value.taxable, value.id)
                        } else {
                            setValue({ title: value, price: '' })
                        }
                    }}
                    onKeyDown={(event)=>{
                        setKeyTab(event.key === "Tab");
                    }}
                    onInputChange={(event, newValue) => {
                        setChange(true);
                    }}
                    renderInput={(params) => (
                        <TextField
                            style={{display: 'inline', paddingTop: '10px'}}
                            {...params}
                            name='description'
                            margin="normal"
                            variant="outlined"
                            InputProps={{ ...params.InputProps, type: 'search' }}
                            onInput={(e) => {
                                if (e.target.value.length > 100){
                                    setErrorLength(true);
                                    e.target.value = e.target.value.slice(0,100);
                                }
                                else{
                                    setErrorLength(false);
                                }

                                const items = [...lineItem];
                                if (e.target.value) {
                                    const value = e.target.value.toLowerCase();
                                    items.sort((a, b) => b.title?.toLowerCase().startsWith(value) - a.title?.toLowerCase().startsWith(value));
                                }
                                setLineItemFiltered(items);
                             }}
                            onBlur={(e) => {
                                    onChangeDesc(e.target.value, pr, taxable, change ? null : companyPriceItemId)
                            }}
                            error={
                                errors.jobItems &&
                                errors.jobItems[indexItem] &&
                                errors.jobItems[indexItem].description &&
                                touched.jobItems &&
                                touched.jobItems[indexItem]
                            }
                            helperText={
                                (
                                    errors.jobItems &&
                                    errors.jobItems[indexItem] &&
                                    errors.jobItems[indexItem].description &&
                                    touched.jobItems &&
                                    touched.jobItems[indexItem]
                                )
                                &&
                                errors.jobItems[indexItem].description
                            }
                        />
                    )}
                />
            </TableCell>
            <TableCell className={classes.bodyCell}>
                <NumberFormat
                    customInput={TextField}
                    allowNegative={false}
                    prefix='$'
                    decimalScale={2}
                    fullWidth
                    disabled={disabled}
                    onChange={(event) => {
                        onChangeItem(event)
                        setPr(event.target.value)
                    }}
                    variant='outlined'
                    value={pr || ''}
                    name='price'
                    onBlur={(event) => {
                        setPr(`$${parseFloat(event.target.value.replace('$', '')).toFixed(2)}`)
                    }}
                    error={
                        errors.jobItems &&
                        errors.jobItems[indexItem] &&
                        errors.jobItems[indexItem].price &&
                        touched.jobItems &&
                        touched.jobItems[indexItem]
                    }
                    helperText={
                        (
                            errors.jobItems &&
                            errors.jobItems[indexItem] &&
                            errors.jobItems[indexItem].price &&
                            touched.jobItems &&
                            touched.jobItems[indexItem]
                        )
                        &&
                        errors.jobItems[indexItem].price
                    }
                />
            </TableCell>
            <TableCell className={classes.bodyCell}>
                <NumberFormat
                    customInput={TextField}
                    allowNegative={false}
                    prefix='$'
                    thousandSeparator
                    fullWidth
                    disabled
                    name='total'
                    variant='outlined'
                    value={total}
                />
            </TableCell>
            <TableCell className={classes.bodyCell} align='center'>
                <FormControlLabel
                    disabled={disabled}
                    className={classes.taxable}
                    checked={taxable || false}
                    name='taxable'
                    control={
                        <GreenCheckbox
                            value="taxable"
                        />
                    }
                    onChange={onChangeItem}
                />
            </TableCell>
            <TableCell className={classes.bodyCell} align='center'>
                <FormControlLabel
                    className={classes.taxable}
                    disabled={disabled}
                    checked={upsale || false}
                    name='upsale'
                    control={
                        <GreenCheckbox
                            value="taxable"
                        />
                    }
                    onChange={onChangeItem}
                />
            </TableCell>
            <TableCell className={classes.bodyCell} align='center' style={{ whiteSpace: 'nowrap' }}>
                <Tooltip title='Copy' arrow>
                    <IconButton onClick={onCopyItem} disabled={disabled}>
                        <FilterNoneIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title='Delete' arrow>
                    <IconButton onClick={onRemoveItem} disabled={disabled || postedToQuickbooks}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    )
}

PriceLine.propTypes = {
    item: PropTypes.object,
    onChangeItem: PropTypes.func,
    onChangeDesc: PropTypes.func,
    onCopyItem: PropTypes.func,
    onRemoveItem: PropTypes.func,
    isDelete: PropTypes.bool,
}

export default PriceLine;
