import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams, Redirect } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/styles'
import {
    Grid, colors, Button, TextField,
    Card, CardContent, CardHeader, Divider,
    CircularProgress, Typography, Collapse , useMediaQuery
} from '@material-ui/core'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import moment from 'moment'
import { JobsiteContacts, Status, Notes, PriceDetails } from './components'
import {AddressLink, ConfirmModal, FormErrorCallback} from 'components'
import { initJob } from 'mockup/JobAddress'
import {Formik, useFormikContext} from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import apiConfig from 'apiConfig'
import axios from 'utils/axios'
import localStorage from "utils/localStorage";
import ScheduleFollowUpModal from "./components/ScheduleFollowUpModal";
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format'
import { errorMessages } from 'common/constants'
import ModalJobTotal from "./components/ModalJobTotal";
import * as Yup from "yup";
import arrayMove from 'array-move'

const useStyles = makeStyles(theme => ({
    circularProgress: {
        marginTop: theme.spacing(3)
    },
    sticky: {
        marginBottom: theme.spacing(2),
        position: 'sticky',
        backgroundColor: '#f4f6f8'
    },
    stickyMobile: {
        marginBottom: theme.spacing(2),
    },
    stickyHead: {
        position: 'relative',
        cursor: 'pointer'
    },
    btnBox: {
        backgroundColor: '#f4f6f8',
    },
    saveBtn: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    btnDis: {
        whiteSpace: 'nowrap',
        color: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'rgb(217 217 217)',
        pointerEvents: 'none'
    },
    address: {
        fontWeight: 500
    },
    btnAction: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    addressLink: {
        textDecoration: 'underline',
        color:'#0000EE'
    }
}));

let mounted = false;

const validateSchema = Yup.object().shape({
    jobItems: Yup.array().nullable()
        .of(
            Yup.object().shape({
                dirty: Yup.boolean(),
                quantity: Yup.number()
                    .when('dirty', {
                        is: true,
                        then: Yup.number()
                            .typeError('Quantity is required')
                            .required("Quantity is required")
                    }),
                description: Yup.string()
                    .max(100, 'Description must be at most 100 characters')
                    .when('dirty', {
                        is: true,
                        then: Yup.string().required("Description is required")
                    }),
                price: Yup.number()
                    .when('dirty', {
                        is: true,
                        then: Yup.number()
                            .typeError('Price is required')
                            .required("Price is required")
                    }),
            })
        )
});

const Information = ({ disabled, customerId, address, job, setJob ,setOpenModal, fuData, bindSubmitForm, setIsSaving, isCustomeService, offsetTop, zIndexCustom = 3, setReload , setReloadOpenEmailModal}) => {

    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
        defaultMatches: true,
      });
    const sm = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { expanded, jobStage , flexibleScheduling} = useSelector(state => state.JobsReducer)
    const dispatch = useDispatch();
    const history = useHistory();
    const { name, id, jobId } = useParams();

    const formikRef = useRef();

    // init data
    
    const [initialValues, setInitialValues] = useState({ ...initJob, jobAddress: address, customerId });
    // address && delete address.id;
    // states
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [openConfirm, setOpenConfirm] = useState(false);
    const [index, setIndex] = useState();
    const [isDelete, setIsDelete] = useState(false);
    const [defaultTax, setDefaultTax] = useState(false);
    const [calcuator, setCalcuator] = useState(false);
    const [showModalSubtotalError, setShowModalSubtotalError] = useState(false);
    const [subTotal, setSubTotal] = useState(0);
    const [sortBy, setSortBy] = useState('DateCreated');
    const [sortType, setSortType] = useState('ASC');
    const [expandPurchaseOrder, setExpandPurchaseOrder] = useState(true);
    const [expandHours, setExpandHours] = useState(true);

    const companyId = localStorage.getCompanyId();
    const toggleExpanded = () => {
        dispatch({ type: 'CHANGE_EXPANDED' })
    }

    // equipment actions
    const onChangeEquipment = (eqm, values, setFieldValue) => {
        eqm = eqm.map(item => {
            var r = item.split(';').map(s => {
                if(!!s) return s;
            }).filter(y => !!y).join(';');
            if(r && r !== ';') return r;
        }).filter(x => !!x);
        const equipment = eqm.join(';')
        // const newEqm = equipment ? (eqm && !equipment.includes(eqm) ? equipment + `;${eqm}` : equipment) : eqm;
        setFieldValue('equipment', equipment)
    }
    const onRemoveEquipment = (eqm, values, setFieldValue) => {
        const equipment = values.equipment;
        const newEqm = equipment.replace(`;${eqm}`, '').replace(`${eqm};`, '').replace(eqm, '');
        setFieldValue('equipment', newEqm)
    }

    // status action
    const onChangeStatus = (value, name, values, setFieldValue) => {
        const date = values.bidAppointment ? moment(values.bidAppointment).format('MM/DD/YYYY') : null;
        const time = values.bidAppointment ? moment(values.bidAppointment).format('LT') : '12:00 AM';
        switch (name) {
            case 'jobStage':
                let jobSplits = values.jobSplits;
                if(jobSplits){
                    jobSplits[0].jobStage = value
                }
                setFieldValue('jobStage', value);
                setFieldValue('jobSplit', jobSplits);
                dispatch({ type: 'CHANGE_STAGE', jobStage: value });
                if (value === '2' && !values.bidCompleted) {
                    setFieldValue('bidCompleted', new Date().toISOString())
                }
                if (value != '4') {
                    setFieldValue('completedDate', null)
                }
                break;
            case 'bidAppointmentDate':
                const bidAppointmentDate = value ? moment(value).format('MM/DD/YYYY') : null;
                bidAppointmentDate && setFieldValue('bidAppointment', new Date(`${bidAppointmentDate} ${time}`).toISOString());
                break;
            case 'bidAppointmentTime':
                const bidAppointmentTime = moment(value).format('LT');
                setFieldValue('bidAppointment', new Date(`${date} ${bidAppointmentTime}`).toISOString());
                break;
            case 'bidTime':
                value && setFieldValue('bidAppointment', new Date(`${date} ${value}`));
                break;
            case 'bidCompleted':
                setFieldValue('bidCompleted', value);
                break;
            default:
                break;
        }
    }

    // prices action
    const onChangePrice = (event, setFieldValue) => {
        const { name, value } = event.target;
        if (name === 'displayOnPrintOutsEmails') {
            setFieldValue('displayOnPrintOutsEmails', event.target.checked);
            if(jobId === ' ')
                localStorage.set("displayOnPrintOutsEmails",event.target.checked);
        } else if (name === 'tipPercent'){
            setFieldValue("tip", value ? parseFloat(value.replace('%', '').replace('$', '')) : 0);
        } else {
            setFieldValue(name, value ? parseFloat(value.replace(/[%$,]/g, '')) : 0);
            setCalcuator(!calcuator);
        }
    }
    const onChangeDiscount = (name, value, setFieldValue) => {
            setFieldValue(name, value ? parseFloat(value.replace('%', '')) : 0);
    }
    const onChangeDiscountType = (value, setFieldValue) => {
        setFieldValue('discountTypeId', value)
        setFieldValue('discountPercent', 0)
        setFieldValue('discountDollars', 0)
        setCalcuator(!calcuator);
    }
    const onChangeDesc = (desc, index, values, setFieldValue, price, taxable, companyPriceItemId) => {
        const jobItems = [...values.jobItems];
        jobItems[index] = { ...jobItems[index], description: desc, price: price.toString().replace('$', ''), taxable: taxable, companyPriceItemId: companyPriceItemId };
        const quantity = parseFloat((jobItems[index]['quantity'] || '0').toString().replace(/[$,]/g, ''))
        const pricenew = parseFloat(price.toString().replace('$', ''))
        jobItems[index] = { ...jobItems[index], ['total']: parseFloat(quantity * pricenew).toFixed(2)};
        setFieldValue('jobItems', jobItems);
    }
    const onAddItem = (values, setFieldValue) => {
        const jobItem = { quantity: '', description: '', price: '', taxable: defaultTax, companyPriceItemId: null , dateCreated : moment(new Date()).format('YYYY/MM/DD hh:mm:ss') };
        const jobItems = Array.isArray(values.jobItems) ? [...values.jobItems, jobItem] : [jobItem]
        setFieldValue('jobItems', jobItems)
        dispatch({ type: 'CHANGE_PRICE_ITEMS', changePriceItems: true });
    }
    const onAddItemTemplate = (values, items, setFieldValue) => {
        const now = new Date();
        if (items?.length > 0) {
            for (var index = 0; index < items.length; index++) {
                // Add a second to each item to fix their order
                items[index].dateCreated = moment(now).add(index, 'second').format('YYYY/MM/DD hh:mm:ss');
            }
        }
        setFieldValue('jobItems', items)
    }
    const onChangeTemplate = (template, setFieldValue) => {
        if (template) setFieldValue('description', template.jobDescription)
        else {
            const data = JSON.parse(sessionStorage.getItem('sw-information'));
            setFieldValue('description', data.description)
        }
    }
    const onChangeItem = (event, index, values, setFieldValue) => {
        const { name, value } = event.target;
        let val = value;

        if (name === 'quantity' || name === 'price') {
            val = value ? parseFloat(value.replace(/[$,]/g, '')) : '';
        }

        if (name === 'taxable' || name === 'upsale') {
            val = event.target.checked;
        }

        const jobItems = [...values.jobItems];
        jobItems[index] = { ...jobItems[index], [name]: val };
        if (name === 'price' || name === 'taxable') {
            jobItems[index] = { ...jobItems[index], companyPriceItemId: null }
        }
        if (name === 'quantity' || name === 'price') {
            const quantity = parseFloat((jobItems[index]['quantity'] || '0').toString().replace(/[$,]/g, ''))
            const price = parseFloat((jobItems[index]['price'] || '0').toString().replace(/[$,]/g, ''))
            jobItems[index] = { ...jobItems[index], ['total']: parseFloat(quantity * price).toFixed(2)};
        }
       
        setFieldValue('jobItems', jobItems);
    }
    const onCopyItem = (item, index, values, setFieldValue) => {
        const jobItems = [...values.jobItems];
        item.dateCreated = moment(new Date()).format('YYYY/MM/DD hh:mm:ss') ;
        const { id, ...newItem } = item;

        jobItems.splice(index + 1, 0, newItem);
        setFieldValue('jobItems', jobItems)
    }

    const onDirty = (dirty, index, values, setFieldValue) => {
        const jobItems = [...values.jobItems];
        jobItems[index] = { ...jobItems[index], dirty };
        setFieldValue('jobItems', jobItems);
    }

    const sortPrices = (oldIndex, newIndex) => {
        if (!formikRef?.current) return;
        let items = formikRef.current.values.jobItems;
        const jobItems = arrayMove([...items], oldIndex, newIndex);
        formikRef.current.setFieldValue('jobItems', jobItems);
        document.body.style.cursor = 'default';
    };

    const onDeleteConfirm = (values, setFieldValue) => {
        const jobItems = [...values.jobItems];
        const item = jobItems.splice(index, 1);
        if (item.jobId && item.id) {
            setIsDelete(true);
            axios.delete(apiConfig.url.BASE_URL + `job/${item.jobId}/priceItem/${item.id}`)
                .then(() => {
                    setFieldValue('jobItems', jobItems);
                    setIsStatus({ failed: false, msg: 'Record Deleted' });
                    setOpenSnackBar(true)
                })
                .catch(() => {
                    setIsStatus({ failed: true, msg: 'Delete failed, please try again later.' });
                    setOpenSnackBar(true)
                })
                .finally(() => {
                    setIsDelete(false);
                    setOpenConfirm(false)
                })
        } else {
            const isNewItem = jobItems.filter(x=> !(x.jobId && x.id)).length > 0;
            dispatch({ type: 'CHANGE_PRICE_ITEMS', changePriceItems: isNewItem });
            setFieldValue('jobItems', jobItems);
            setOpenConfirm(false);
        }
    }
    const redirectToNewJob = id => {
        const path = history.location.pathname.replace('/ /information', `/${id}/information`);
        history.push(path);
    }

    const redirectToSplitTab = () => {
        const path = history.location.pathname.replace('/information', `/split-job`);
        history.push(path);
        setShowModalSubtotalError(false)
    }

    const fetchJobItems = () => {
        if((localStorage.getItem('sortJobAdd') ? localStorage.getItem('sortJobAdd') == 'true' : false))
        {
            setSortBy('DateCreated');
        }
        localStorage.set("sortJobAdd", false);
        if(jobId)
        {
            axios.get(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_PRICE_ITEMS.replace('{jobId}', jobId),
                {
                    params:
                        {
                            sortBy: sortBy,
                            sortType: sortType,
                        }
                }
            )
                .then(res => {
                    formikRef.current.setFieldValue('jobItems', res.data || [])
                })
                .catch(err => console.log(err))
        }
    }

    const loadDocument = (type) => {
        setSortBy(type)
        setSortType(type == sortBy ? (sortType === 'ASC' ? 'DESC' : 'ASC') : 'ASC');
    }

    useEffect(() => {
        if(formikRef?.current){
            let jobItems = formikRef.current.values.jobItems;
            if(sortBy == 'Quantity')
            {
                jobItems = sortType == 'ASC' ? jobItems.sort((a, b) => (a.quantity - b.quantity)) : jobItems.sort((a, b) => (b.quantity - a.quantity))
            }
            else if(sortBy == 'Description'){
                jobItems = sortType == 'ASC' ? jobItems.sort((a, b) => a.description.localeCompare(b.description)) : jobItems.sort((a, b) => b.description.localeCompare(a.description))
            }
            else if(sortBy == 'Price'){
                jobItems = sortType == 'ASC' ? jobItems.sort((a, b) => (a.price - b.price)) : jobItems.sort((a, b) => (b.price - a.price))
            }
            else if(sortBy == 'Total'){
                jobItems = sortType == 'ASC' ? jobItems.sort((a, b) => (a.total - b.total)) : jobItems.sort((a, b) => (b.total - a.total))
            }
            else if(sortBy == 'Taxable'){
                jobItems = sortType == 'ASC' ? jobItems.sort((a, b) => (a.taxable - b.taxable)) : jobItems.sort((a, b) => (b.taxable - a.taxable))
            }
            else {
                jobItems = sortType == 'ASC' ? jobItems.sort((a, b) => (a.upsale - b.upsale)) : jobItems.sort((a, b) => (b.upsale - a.upsale))
            }
            formikRef.current.setFieldValue('jobItems', jobItems);
        }
        else if (job && job.id) {
            fetchJobItems();
        }
    }, [sortBy, sortType])

    useEffect(() => {
        if (job && job.id) {
            fetchJobItems();
        }
    }, [job])

    useEffect(() => {

        mounted = true;

        return () => mounted = false;
    }, [jobId]);

    useEffect(() => {
        if (job) {

            localStorage.set('list_origin_template', JSON.stringify(job.jobItems || []));

            const { jobItems, discountPercent, taxPercent } = job;
            if (Array.isArray(jobItems)) {
                let total = 0;
                jobItems.map(item => {
                    const { quantity, price } = item;
                    const totalPrice = quantity * price;
                    total += totalPrice;
                });

                const priceDiscount = discountPercent ? (total * discountPercent / 100) : 0;
                let subtotal = (total - priceDiscount);
                sessionStorage.setItem('subtotal', subtotal.toFixed(2))
            }
        }
    }, [job])

    useEffect(() => {
        if (address && !address.id) {
            return (
                <Redirect to={`/customers/${encodeURIComponent(name)}/${id}/job-addresses`} />
            )
        }
    }, [address])

    useEffect(() => {
        if(jobId === ' '){
            if (initialValues.jobItems && initialValues.jobItems.length > 0)
                initialValues.jobItems[0].dateCreated = moment(new Date()).format('YYYY/MM/DD hh:mm:ss');
            initialValues.displayOnPrintOutsEmails = localStorage.getItem('displayOnPrintOutsEmails') ? localStorage.getItem('displayOnPrintOutsEmails') == 'true' : true;
            setInitialValues(initialValues);
        }
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_PRICE_TAX.replace('{companyId}', companyId))
            .then(res => {
                setDefaultTax(res.data)
            })
    }, [])

    useEffect(() => {
        if (!formikRef?.current) return;
        let jobSplits = formikRef.current.values.jobSplits;
        if(jobSplits){
            jobSplits[0].jobStage = jobStage;
        }
        formikRef.current.setFieldValue('jobStage', jobStage);
        formikRef.current.setFieldValue('jobSplit', jobSplits);
        if (jobStage === '2' && !formikRef.current.values.bidCompleted) {
            formikRef.current.setFieldValue('bidCompleted', new Date().toISOString())
        }
        if (jobStage != '4') {
            formikRef.current.setFieldValue('completedDate', null)
        }
    }, [jobStage]);

    useEffect(() => {
        if (!formikRef?.current) return;
        formikRef.current.setFieldValue('flexibleScheduling', flexibleScheduling);
    }, [flexibleScheduling]);

    if (jobId !== ' ' && (!job || Object.keys(job).length === 0 || !address)) {
        return (
            <Grid container justifyContent='center' alignItems='center'>
                <CircularProgress className={classes.circularProgress} size={50} />
            </Grid>
        )
    }

    return (
        <>
            <Formik
                innerRef={formikRef}
                initialValues={jobId != ' ' ? job : initialValues}
                validationSchema={validateSchema}
                enableReinitialize={true}
                onSubmit={(values) => {
                    setIsSaving(true);
                    // const value = { ...values, completedDate: (values.jobStage != 4 ? values.completedDate : ''),  bidAppointment: values.bidAppointment ? new Date(values.bidAppointment).toISOString() : '' }
                    if (!!jobId && jobId != ' ') {
                        // update Job
                        setIsSaving(true);
                        axios.put(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_UPDATE, values)
                            .then(res => {
                                if (values.jobStage === '6') {
                                    var primary = values.jobSplits.find(x => x.isPrimaryJob);
                                    if (primary) {
                                        primary.scheduleDate = null;
                                    }
                                }
                                setJob(values);

                                //check total amount job split
                                let total = 0;
                                for (let i = 0; i < job.jobSplits.length; i++) {
                                    total += job.jobSplits[i].total;
                                }
                                if(job.jobSplits.length > 1 && job.distributeSplitsEqually != true && total.toFixed(2) != parseFloat(subTotal).toFixed(2)){
                                    setShowModalSubtotalError(true);
                                }
                                //end
                                setReload();
                                setIsStatus({ failed: false, msg: 'Record Updated' });
                                setOpenSnackBar(true);
                                setReloadOpenEmailModal();
                            })
                            .catch(() => {
                                setIsStatus({ failed: true, msg: 'Update failed, please try again later' });
                                setOpenSnackBar(true);
                            })
                            .finally(() => setIsSaving(false))
                    } else {
                        // add new Job
                        setIsSaving(true);
                        values.jobItems = values.jobItems && values.jobItems
                            .filter(item => item.dirty)
                            .map(item => {
                                delete item.jobId
                                return item
                            })
                        axios.post(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_ADD, values)
                            .then(res => {
                                setIsStatus({ failed: false, msg: 'Add successfully.' });
                                setOpenSnackBar(true);
                                localStorage.set("sortJobAdd", true);
                                redirectToNewJob(res.data.id)
                            })
                            .catch(() => {
                                setIsStatus({ failed: true, msg: 'Add failed, please try again later' });
                                setOpenSnackBar(true);
                            })
                            .finally(() => setIsSaving(false))
                    }
                }}>
                {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                    submitCount,
                    isValid,
                    submitForm
                }) => {
                    bindSubmitForm(submitForm);
                    return (
                    <form>
                        <FormErrorCallback
                            submitCount={submitCount}
                            isValid={isValid}
                            onSubmissionError={(errors) => {
                                if(errors && errors.jobItems && errors.jobItems.length > 0) {
                                    for (let index = 0; index < errors.jobItems.length; index++) {
                                        const error = errors.jobItems[index];
                                        if (error) {
                                            if(error.description) {
                                                setIsStatus({ failed: true, msg: error.description });
                                            }
                                            if(error.quantity) {
                                                setIsStatus({ failed: true, msg: error.quantity});
                                            }
                                            if(error.price) {
                                                setIsStatus({ failed: true, msg: error.price});
                                            }
                                            setOpenSnackBar(true);
                                            break;
                                        }
                                    }
                                }
                            }}
                        />
                        <Grid container spacing={3} className={ isDesktop ? classes.sticky : classes.stickyMobile} style={{ zIndex: zIndexCustom, top: offsetTop }}>
                            <Grid item xs={12} style={{ margin: '-12px 0' }}>
                                <Card style={{ width: '100%' }}>
                                    <CardHeader title={isCustomeService ? <> <span>Job Details</span><span style={{color: 'red'}}> - Customer Service</span> </>  : 'Job Details'}
                                        className={classes.stickyHead}
                                        action={<KeyboardArrowDown />}
                                        onClick={toggleExpanded} />
                                    <Divider />
                                    <Collapse in={expanded} timeout='auto' unmountOnExit>
                                        <CardContent>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={4}>
                                                    <Card style={{ height: '100%' }}>
                                                        <CardHeader title='Job Address' />
                                                        <Divider />
                                                        <CardContent style={{ padding: '16px' }}>
                                                            <Typography className={classes.address}>{address.name || ''}</Typography>
                                                            <AddressLink
                                                                address={{
                                                                    ...address,
                                                                    state: { name: address.stateName }
                                                                }}
                                                                className={classes.address}
                                                            />
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                                <Grid item xs={12} sm={8}>
                                                    <TextField
                                                        fullWidth
                                                        disabled={disabled}
                                                        multiline
                                                        rows={7}
                                                        label='Job Description'
                                                        name='description'
                                                        variant='outlined'
                                                        value={values.description || ''}
                                                        onChange={event => setFieldValue('description', event.target.value)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Collapse>
                                </Card>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={4}>
                                <Card style={{ marginBottom: '16px' }}>
                                    <CardHeader title='Purchase Order'
                                        action={sm && (expandPurchaseOrder ? <KeyboardArrowUp /> : <KeyboardArrowDown />)}
                                        onClick={() => sm && setExpandPurchaseOrder(!expandPurchaseOrder)} />
                                    <Divider />
                                    <Collapse in={!sm || expandPurchaseOrder} timeout='auto' unmountOnExit>
                                        <CardContent>
                                            <TextField
                                                fullWidth
                                                disabled={disabled}
                                                label='Purchase Order Number'
                                                name='purchaseOrder'
                                                variant='outlined'
                                                value={values.purchaseOrder || ''}
                                                onChange={event => setFieldValue('purchaseOrder', event.target.value)}
                                            />
                                        </CardContent>
                                    </Collapse>
                                </Card>
                                <Card>
                                    <CardHeader title='Hours'
                                        action={sm && (expandHours ? <KeyboardArrowUp /> : <KeyboardArrowDown />)}
                                        onClick={() => sm && setExpandHours(!expandHours)} />
                                    <Divider />
                                    <Collapse in={!sm || expandHours} timeout='auto' unmountOnExit>
                                        <CardContent>
                                            <NumberFormat
                                                fullWidth
                                                customInput={TextField}
                                                allowNegative={false}
                                                style={{ marginBottom: '8px' }}
                                                disabled={disabled}
                                                label='Estimated Hours'
                                                name='estimatedHours'
                                                variant='outlined'
                                                value={values.estimatedHours || ''}
                                                onChange={event => setFieldValue('estimatedHours', event.target.value)}
                                            />
                                            <TextField
                                                fullWidth
                                                disabled
                                                label='Actual Hours'
                                                name='actualHours'
                                                variant='outlined'
                                                value={values.actualHours || ''}
                                                onChange={event => setFieldValue('actualHours', event.target.value)}
                                            />
                                        </CardContent>
                                    </Collapse>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <JobsiteContacts contacts={job.jobAddress && job.jobAddress.contacts} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Status
                                    disabled={disabled}
                                    isCustomeService={isCustomeService}
                                    status={{
                                        jobSplits: values.jobSplits,
                                        jobStage: values.jobStage,
                                        scheduleDate: values.scheduleDate,
                                        bidAppointment: values.bidAppointment,
                                        completedDate: values.completedDate,
                                        bidCompleted: values.bidCompleted,
                                        id: values.id,
                                        description: values.description,
                                        companyId: values.companyId,
                                    }}
                                    onChange={(value, name) => onChangeStatus(value, name, values, setFieldValue)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Notes
                                    disabled={disabled}
                                    note={{
                                        equipment: values.equipment,
                                        jobNote: values.jobNote,
                                        jobsiteNote: values.jobsiteNote,
                                        showEquipmentOnSchedule: values.showEquipmentOnSchedule,
                                        showJobNoteOnSchedule: values.showJobNoteOnSchedule,
                                        showJobsiteNoteOnSchedule: values.showJobsiteNoteOnSchedule
                                    }}
                                    onChange={event => setFieldValue(event.target.name, event.target.value)}
                                    onCheck={event => setFieldValue(event.target.name, event.target.checked)}
                                    onChangeEquipment={(eqm) => onChangeEquipment(eqm, values, setFieldValue)}
                                    clearEquipment={(eqm) => onRemoveEquipment(eqm, values, setFieldValue)}
                                />
                            </Grid>
                            <Grid style={{ width: '100%' }} item>
                                <PriceDetails
                                    errors={errors}
                                    touched={touched}
                                    disabled={disabled}
                                    jobId={jobId}
                                    jobDescription={values?.description ?? ''}
                                    calcuator={calcuator}
                                    prices={{
                                        jobItems: values.jobItems,
                                        displayOnPrintOutsEmails: values.displayOnPrintOutsEmails,
                                        discountPercent: values.discountPercent,
                                        discountDollars: values.discountDollars,
                                        discountTypeId: values.discountTypeId,
                                        taxPercent: values.taxPercent,
                                        tip: values.tip,
                                        tipDate: values.tipDate
                                    }}
                                    onChange={event => onChangePrice(event, setFieldValue)}
                                    onChangeDiscount={(name, value) => onChangeDiscount(name, value, setFieldValue)}
                                    onChangeDiscountType={value => onChangeDiscountType(value, setFieldValue)}
                                    onAddItem={() => onAddItem(values, setFieldValue)}
                                    onAddItemTemplate={(items) => onAddItemTemplate(values, items, setFieldValue)}
                                    onChangeTemplate={(template) => onChangeTemplate(template, setFieldValue)}
                                    onChangeItem={(event, index) => onChangeItem(event, index, values, setFieldValue)}
                                    onChangeDesc={(value, index, price, taxable, companyPriceItemId) => onChangeDesc(value, index, values, setFieldValue, price, taxable, companyPriceItemId)}
                                    onCopyItem={(item, index) => onCopyItem(item, index, values, setFieldValue)}
                                    onRemoveItem={index => {
                                        setIndex(index);
                                        setOpenConfirm(true)
                                    }}
                                    setTotalSub={(value) => {
                                        setSubTotal(value)
                                    }}
                                    onDirty={(dirty, index) => onDirty(dirty, index, values, setFieldValue)}
                                    sortPrices={(oldIndex, newIndex) => sortPrices(oldIndex, newIndex)}
                                    postedToQuickbooks={values.queuedToQuickbooks}
                                    loadDocument={loadDocument}
                                    sortBy={sortBy}
                                    sortType={sortType}
                                />
                            </Grid>
                        </Grid>
                        <ConfirmModal
                            openConfirm={openConfirm}
                            closeConfirm={() => setOpenConfirm(false)}
                            onConfirm={() => onDeleteConfirm(values, setFieldValue)}
                            isProgress={isDelete}
                        />

                    </form>
                )}}
            </Formik>
            <ModalJobTotal
                openConfirm={showModalSubtotalError}
                closeConfirm={() => setShowModalSubtotalError(false)}
                onConfirm={redirectToSplitTab}
            />
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackBar(false)}>
                <Alert
                    elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>


        </>
    )
}

export default Information;

Information.propTypes = {
    bindSubmitForm: PropTypes.func
};
