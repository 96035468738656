import React, { useEffect, useState, useRef, useCallback } from "react";
import { Redirect, Link, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Tabs, Tab, Divider, colors, Typography, Grid,
  Button, CircularProgress,
  CardActions, Card, Modal, CardHeader, CardContent,
  TextField, createTheme, useMediaQuery , FormControlLabel
} from "@material-ui/core";
import { Page, KeyboardDatePicker, GreenCheckbox } from "components";

import {
  Information,
  CombineJobs,
  SplitJob,
  Commissions,
  Completed
} from "./components";
import axios from "../../utils/axios";
import apiConfig from "../../apiConfig";
import { useSelector, useDispatch } from 'react-redux'
import moment from "moment";
import ScheduleFollowUpModal from "./components/Information/components/ScheduleFollowUpModal";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import ModalCopy from "./components/Information/components/ModalCopy";
import CopyJobModal from "../CustomerDetails/components/JobAddresses/components/CopyJobModal";
import EmailModal from "../CustomerDetails/components/JobAddresses/components/EmailModal";
import Attachments from "./components/Attachments";
import { checkFeature, FeatureFlags } from "FeatureService";
import {PERMISSIONS} from "common/permissions";
import localStorage from 'utils/localStorage'

const useHeight = (elementRef, ...deps) => {
  const [height, setHeight] = useState(null);

  const updateHeight = useCallback(() => {
      if(elementRef && elementRef.current) {
          const { height } = elementRef.current.getBoundingClientRect();
          setHeight(height);
      }
  }, [elementRef, ...deps]);

  useEffect(() => {
      updateHeight();
      window.addEventListener('resize', updateHeight);
      return () => {
          window.removeEventListener('resize', updateHeight);
      }
  }, [updateHeight])

  return [height]
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    paddingTop: 24,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      paddingTop: 12
    }
  },
  tabs: {

  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  },
  goBack: {
    color: "#263238",
    display: "inline-block",
    "&:hover": {
      color: "#2632389e"
    }
  },
  sticky: {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    position: "sticky",
    top: 0,
    zIndex: 3,
    backgroundColor: "#f4f6f8",
    alignItems: "center"
  },
  headerButtons: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0 !important',
    marginLeft: 12,
    marginRight: 12,
    [theme.breakpoints.down("sm")]: {
      flexWrap: 'nowrap',
      whiteSpace: 'nowrap',
      overflow: 'auto'
    }
  },
  headerControls: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    paddingTop: '5px !important',
    paddingBottom: '0 !important'
  },
  saveBtn: {
    [theme.breakpoints.down("sm")]: {
    },
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    "&:hover": {
      backgroundColor: colors.green[900]
    }
  },
  address: {
    fontWeight: 500
  },
  btnAction: {
    [theme.breakpoints.down("sm")]: {
    },
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    "&:hover": {
      backgroundColor: colors.green[900]
    }
  },
  styleModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    boxShadow: theme.shadows[20],
    width: 400,
    maxHeight: "100%",
    overflowY: "auto",
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      width: 500
    }
  },
  conButton: {
    padding: "16px",
    justifyContent: "flex-end"
  },
  button: {
    flexShrink: "0",
    backgroundColor: colors.green[600],
    "&:hover": {
      backgroundColor: colors.green[900]
    }
  },
  btnBox: {
    display: "inline-flex",
    position: "relative",
    padding: '4px'
  }
}));
let mounted = false;
const CustomerJobs = props => {
  const classes = useStyles();
  const sm = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const completedDateRef = useRef()
  const ad = JSON.parse(sessionStorage.getItem("sw-address"));

  const { match, history } = props;
  const { id, tab } = match.params;
  const name = decodeURIComponent(match.params.name);
  const { jobId } = useParams();

  const dispatch = useDispatch();

  const [states, setStates] = useState();
  const [addressID, setAddressID] = useState();
  const [address, setAddress] = useState(ad);
  const [load, setLoad] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [fuData, setFuData] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [makeCompleting, setMakeCompleting] = useState(false);
  const [isStatus, setIsStatus] = useState({ failed: false, msg: "" });
  const [openSnackbar, setOpenSnackBar] = useState(false);
  const [primaryJob, setPrimaryJob] = useState();
  const [information, setInformation] = useState({});
  const [loading, setLoading] = useState(false);
  const [showModalCopy, setShowModalCopy] = useState(false);
  const [newJob, setNewJob] = useState(false);
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const [changeSplitsEqually, setChangeSplitsEqually] = useState(false);
  const [sentModalData, setSentModalData] = useState({
    open: false,
    to: null,
    cc: null
  });
  const [invoiceGenerated, setInvoiceGenerated] = useState(false);
  const [completedDate, setCompletedDate] = useState(null);
  const [stages, setStages] = useState([]);
  const [jobStage, setJobStage] = useState(1);
  const [flexibleScheduling , setFlexibleScheduling] = useState(false);
  const [enableWorkOrder, setEnableWorkOrder] = useState(true);

  const headerButtonsRef = useRef(null);
  const [headerHeight] = useHeight(headerButtonsRef, tab, sm);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const jobCompletionBack = JSON.parse(params.get('completion'));
  const scheduledBack = params.get('schedule');
  const userRoles = localStorage.getUserRoles();
  const isSchedule = userRoles.includes(PERMISSIONS.COMPANY_ADMINISTRATOR) || userRoles.includes(PERMISSIONS.SUPER_ADMIN);
  const isFieldTechnician = !userRoles || (userRoles.length === 1 && userRoles[0] === PERMISSIONS.FIELD_TECHNICIAN);
  const { changePriceItems } = useSelector(state => state.JobsReducer)
  const handleTabsChange = (event, value) => {
    history.push(value);
  };

  const tabs = [
    { value: "information", label: "Information" },
    //{ value: "combine-jobs", label: "Combine Jobs" },
    { value: "attachments", label: "Attachments" },
    { value: "split-job", label: "Split Job" },
    { value: "commissions", label: "Commissions" },
    { value: "completed", label: "Completed" }
  ];

  const reloadData = () => {
    setLoad(!load);
  };

  let submitMyFormOne = null;
  const handleSubmitMyForm = () => {
    if (submitMyFormOne) {
      submitMyFormOne();
    }
  };
  const bindSubmitFormOne = submitForm => {
    submitMyFormOne = submitForm;
  };

  const redirectToNewJob = () => {
    if (newJob) {
      const path = `/customers/${encodeURIComponent(name)}/${newJob.customerId}/jobs/${newJob.id}/information`;
      const win = window.open(path, "_blank");
      win.focus();
      setShowModalCopy(false);
    }
  };
  const onScheduleJob = () => {
    if (newJob) {
      setShowModalCopy(false);
      history.push('/schedules', {jobId: newJob.id});
    }
  };
  const redirectToRoutesSchedules = () => {
    if(primaryJob?.scheduleDate){
      history.push(`/schedules/routes/${moment.utc(primaryJob.scheduleDate+'.000Z').format('YYYY-MM-DD')}`, {jobId: jobId, mapVisible : false} );
    }else{
      const { latitude, longitude } = information?.jobAddress ?? {};
      if (latitude != null && longitude != null) {
        dispatch({ type: 'LOCATE_ON_ROUTE_COORD', locateOnRouteCoord: { latitude, longitude } });
      }
      history.push(`/schedules/routes/${moment.utc(Date()).format('YYYY-MM-DD')}`, {jobId: jobId, mapVisible : false});
    }
  };
  const redirectSchedules = (e) => {
    e.preventDefault();
    history.push(`/schedules/routes/${scheduledBack}`, {jobId});
  };

  const handleChangeJobStage = value => {
    setJobStage(value);
    dispatch({ type: 'CHANGE_STAGE', jobStage: value });
  }

  const handleChangeJFlexibleScheduling = value => {
    setFlexibleScheduling(value);
    dispatch({ type: 'CHANGE_ANYTIME', flexibleScheduling: value });
  }
  const markComplete = (date) => {
    const data = {
      jobId: jobId,
      completedDate: date || completedDate
    };

    // check existed completedDate
    const _completedDate = primaryJob && primaryJob.completedDate && `${primaryJob.completedDate}.000Z` || null
    if (_completedDate === data.completedDate) return

    setMakeCompleting(true);
    axios
      .patch(
        apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_MARK_COMPLETE,
        data
      )
      .then(res => {
        reloadData();
        setIsStatus({ failed: false, msg: data.completedDate ? "Job Completed" : "Job is no longer marked complete." });
        setOpenSnackBar(true);
      })
      .catch(err => {
        setIsStatus({ failed: true, msg: "Change completedDate failed." });
        setOpenSnackBar(true);
      })
      .finally(() => setMakeCompleting(false));
  };

  const handleChangeCompletedDate = date => {
    setCompletedDate(date)
    const input = completedDateRef.current.childNodes[1].childNodes[0];
    if (input !== document.activeElement) {
      markComplete(date)
    }
  }
  const handlePressEnter = event => {
    if (event.key === 'Enter' && completedDateRef.current) {
      const input = completedDateRef.current.childNodes[1].childNodes[0];
      input.blur()
    }
  }

  const updateIsEqually = (isEqual) => {
    setChangeSplitsEqually(true)
    const payload = { ...information, distributeSplitsEqually: isEqual }
    axios.put(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_UPDATE, payload).then(() => {
      setInformation(payload)
      setIsStatus({ failed: false, msg: "Split distribution update complete" });
      setOpenSnackBar(true);
    }).catch(() => {
      setIsStatus({ failed: true, msg: "Update distribute splits equally failed" });
      setOpenSnackBar(true);
    }).finally(() => setChangeSplitsEqually(false))
  }

  useEffect(() => {
    axios
      .get(apiConfig.url.BASE_URL + apiConfig.url.EMPLOYEE_STATES_LIST)
      .then(res => {
        res.data = res.data.sort((a, b) => a.name.localeCompare(b.name));
        setStates(res.data);
      });

    axios.get(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMERS_JOB_STATE)
      .then(res => {
        if (primaryJob?.isCustomerService) {
          setStages(res.data.filter(x => x.order !== 1 && x.order !== 2));
        } else {
          setStages(res.data);
        }
      })
  }, []);

  useEffect(() => {
    mounted = true;
    if (jobId !== " ") {
      setLoading(true);
      axios
        .get(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB + jobId)
        .then(res => {
          if (mounted) {
            res.data.bidAppointment = moment
              .utc(res.data.bidAppointment)
              .local()
              .toISOString();
            res.data.scheduleDate = res.data.scheduleDate
              ? new Date(res.data.scheduleDate + "Z")
              : null;
            setInformation(res.data);
            handleChangeJobStage(res.data.jobStage);
            handleChangeJFlexibleScheduling(res.data.flexibleScheduling || false);
            setAddressID(res.data.jobAddress.id);
            sessionStorage.setItem("sw-information", JSON.stringify(res.data));
          }
        })
        .catch()
        .finally(() => {
          setLoading(false);
        });
    }
    else
    {
      handleChangeJobStage(1);
      handleChangeJFlexibleScheduling(false);
    }
    return () => (mounted = false);
  }, [jobId, load]);

  useEffect(() => {
    if (jobId !== " " && information?.jobAddress && states) {
      const state = states.find(x => x.id === information.jobAddress.stateId)?.name ?? "";
      const ad = { ...information.jobAddress, state, contacts: information.contacts };
      setAddress(ad);
    }
  }, [jobId, information, states]);

  useEffect(() => {
    if (information && information.id) {
      axios
        .get(
          apiConfig.url.BASE_URL +
          apiConfig.url.CUSTOMER_JOB_FOLLOW_UP.replace("{jobId}", jobId)
        )
        .then(res => {
          if (res.data[0]) {
            setFuData(res.data[0]);
          } else {
            setFuData(null);
          }
        });
    }
  }, [load, information]);

  useEffect(() => {
    if (information && information.id) {
      const prJob = information.jobSplits.find(x => x.isPrimaryJob == true)
      if (prJob) {
        if (prJob.isCustomerService) setStages(stages.filter(x => x.order !== 1 && x.order !== 2));
        setPrimaryJob(prJob);
        prJob.completedDate && setCompletedDate(`${prJob.completedDate}.000Z`)
      }
    }
  }, [information]);
  useEffect(() => {
    mounted = true;
    (async () => {
        const isEnableWorkOrder = await checkFeature(FeatureFlags.WORK_ORDER);
        setEnableWorkOrder(isEnableWorkOrder)
        }
    )();
  return () => mounted = false;
}, [])
  const [openEmailModal, setOpenEmailModal] = useState(false);

  const reloadOpenEmailModal = () => {
    if (changePriceItems){
      setOpenEmailModal(!openEmailModal);
    }
    dispatch({ type: 'CHANGE_PRICE_ITEMS', changePriceItems: false });
  };
  if (!tabs.find(t => t.value === tab)) {
    return <Redirect to="/errors/error-404" />;
  }

  return (
    <Page className={classes.root} title="Job Management">
      {!isFieldTechnician &&
      <Link to={`/customers/${encodeURIComponent(name)}/${id}/job-addresses`}>
        <Typography className={classes.goBack}>
          {`Go back to ${name === " " ? "Job Addresses" : name}`}
        </Typography>
      </Link>
      }
      {jobCompletionBack &&
      <Link to={`/job-completion?${new URLSearchParams(jobCompletionBack).toString()}`}>
        <Typography style={{marginLeft: '40px', display: 'inline-block'}} className={classes.goBack}>
          {`Go back to Job Completion`}
        </Typography>
      </Link>
      }
      {scheduledBack &&  <Link to={`/schedules/routes/${scheduledBack}`} onClick={redirectSchedules}>
        <Typography style={{marginLeft: '40px', display: 'inline-block'}} className={classes.goBack}>
          {`Go back to Schedule`}
        </Typography>
      </Link>
      }
      <Tabs
        className={classes.tabs}
        onChange={handleTabsChange}
        scrollButtons="auto"
        value={tab}
        variant="scrollable"
      >
        {tabs.map(tab => (
          <Tab
            disabled={tab.value !== "information" && !information.id}
            key={tab.value}
            label={tab.label}
            value={tab.value}
          />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      <Grid container spacing={3} className={classes.sticky} ref={headerButtonsRef}>
        <Grid item xs={12} className={classes.headerButtons}>
          {(tab === "information" || tab === "completed") && (
            <CardActions className={classes.btnBox}>
              <Button
                className={classes.saveBtn}
                onClick={()=>{
                  dispatch({ type: 'CHANGE_PRICE_ITEMS', changePriceItems: false });
                  handleSubmitMyForm();
                }}
                disabled={isFieldTechnician || (!information.id && jobId !== ' ') || (primaryJob && primaryJob.completedDate) || isSaving}
                variant="contained"
              >
                Save
              </Button>
              {isSaving && <CircularProgress size={24} />}
            </CardActions>
          )}
          <CardActions className={classes.btnBox}>
            <Button
              className={classes.btnAction}
              disabled={isFieldTechnician || !information.id}
              variant="contained"
              onClick={() => {
                if(changePriceItems)
                  handleSubmitMyForm();
                else
                  setOpenEmailModal(true)
              }}
            >
              {enableWorkOrder ? 'Quote/Invoice/Work Order' : 'Quote/Invoice'}
            </Button>
          </CardActions>
          <CardActions className={classes.btnBox}>
            <Button
              variant={"contained"}
              disabled={isFieldTechnician || !information.id}
              className={classes.btnAction}
              onClick={() => {
                setOpenCopyModal(true);
              }}
            >
              Copy & Schedule
            </Button>
          </CardActions>
          <CardActions className={classes.btnBox}>
            <Button
              variant={"contained"}
              disabled={isFieldTechnician || !information.id}
              className={classes.btnAction}
              onClick={setOpenModal}
            >
              {fuData ? "View Follow Up" : "Add Follow Up"}
            </Button>
          </CardActions>

          {isSchedule && <CardActions className={classes.btnBox}>
            <Button
              variant="contained"
              disabled={isFieldTechnician || !information.id}
              className={classes.btnAction}
              onClick={redirectToRoutesSchedules}
            >
              See on Schedule
            </Button>
          </CardActions>}
          {
            !sm && <>
              {
                stages && stages.length > 0 &&
                <CardActions className={classes.btnBox}>
                  <TextField
                    disabled={isFieldTechnician || !!primaryJob?.completedDate}
                    label='Stage'
                    name='jobStage'
                    variant='outlined'
                    size='small'
                    select
                    SelectProps={{ native: true }}
                    value={jobStage || ''}
                    onChange={e => handleChangeJobStage(e.target.value)}
                  >
                    {stages.map(stage => (
                        <option disabled={stage.order == 4 || stage.order === 5}
                            key={stage.id} value={stage.id}>
                            {stage.description}
                        </option>
                    ))}
                  </TextField>
                </CardActions>
              }

              <CardActions className={classes.btnBox}>
                <KeyboardDatePicker
                  disabled
                  style={{ width: 145 }}
                  label='Scheduled Date'
                  name='scheduleDate'
                  size='small'
                  value={primaryJob?.scheduleDate && moment.utc(primaryJob.scheduleDate+'.000Z').format("MM/DD/YYYY") || null}
                />
              </CardActions>

              {tab === "completed" && (
                <CardActions className={classes.btnBox}>
                  <KeyboardDatePicker
                    style={{ width: 145 }}
                    disabled={isFieldTechnician || !information.id}
                    getInputRef={el => completedDateRef.current = el}
                    label='Completed Date'
                    value={completedDate}
                    onChange={handleChangeCompletedDate}
                    onBlur={() => markComplete()}
                    onKeyDown={handlePressEnter}
                    size='small'
                  />
                  {makeCompleting && <CircularProgress size={24} />}
                </CardActions>
              )}
              <CardActions className={classes.btnBox}>
                <FormControlLabel
                    className={classes.checkbox}
                    name='flexibleScheduling'
                    checked={flexibleScheduling || false}
                    onChange={event => {
                      handleChangeJFlexibleScheduling(event.target.checked)
                    }}
                    control={
                        <GreenCheckbox />
                    }
                    label='Anytime Today/Flexible'
                />
              </CardActions>
            </>
          }
        </Grid>
        {
          sm && <Grid item xs={12} className={classes.headerControls}>
            {
              stages && stages.length > 0 &&
              <CardActions className={classes.btnBox}>
                <TextField
                  disabled={isFieldTechnician || !!primaryJob?.completedDate}
                  label='Stage'
                  name='jobStage'
                  variant='outlined'
                  size='small'
                  select
                  SelectProps={{ native: true }}
                  value={jobStage || ''}
                  onChange={e => handleChangeJobStage(e.target.value)}
                >
                  {stages.map(stage => (
                      <option disabled={stage.order == 4 || stage.order === 5}
                          key={stage.id} value={stage.id}>
                          {stage.description}
                      </option>
                  ))}
                </TextField>
              </CardActions>
            }

            <CardActions className={classes.btnBox}>
              <KeyboardDatePicker
                disabled
                style={{ width: 145 }}
                label='Scheduled Date'
                name='scheduleDate'
                size='small'
                value={primaryJob?.scheduleDate && moment.utc(primaryJob.scheduleDate+'.000Z').format("MM/DD/YYYY") || null}
              />
            </CardActions>

            {tab === "completed" && (
              <CardActions className={classes.btnBox}>
                <KeyboardDatePicker
                  style={{ width: 145 }}
                  disabled={isFieldTechnician || !information.id}
                  getInputRef={el => completedDateRef.current = el}
                  label='Completed Date'
                  value={completedDate}
                  onChange={handleChangeCompletedDate}
                  onBlur={() => markComplete()}
                  onKeyDown={handlePressEnter}
                  size='small'
                />
                {makeCompleting && <CircularProgress size={24} />}
              </CardActions>
            )}

            <CardActions className={classes.btnBox}>
              <FormControlLabel
                  className={classes.checkbox}
                  name='flexibleScheduling'
                  checked={flexibleScheduling || false}
                  onChange={event => {
                    handleChangeJFlexibleScheduling(event.target.checked)
                  }}
                  control={
                      <GreenCheckbox />
                  }
                  label='Anytime Today/Flexible'
              />
            </CardActions>
          </Grid>
        }
      </Grid>
      <div className={classes.content}>
        {tab === "information" && (
          <Information
            disabled={(isFieldTechnician || (primaryJob && primaryJob.completedDate)) ? true : false}
            fuData={fuData}
            setOpenModal={() => setOpenModal(true)}
            customerId={id}
            address={address}
            job={information}
            setJob={job => setInformation(job)}
            bindSubmitForm={bindSubmitFormOne}
            setIsSaving={setIsSaving}
            isCustomeService={primaryJob && primaryJob.isCustomerService}
            zIndexCustom={3}
            offsetTop={headerHeight ?? 0}
            setReload={()=> reloadData()}
            setReloadOpenEmailModal={()=> reloadOpenEmailModal()}
          />
        )}
        {tab === "combine-jobs" && (
          <CombineJobs
            name={name}
            fuData={fuData}
            setOpenModal={() => setOpenModal(true)}
            information={information}
            states={states}
            addressId={addressID}
            bindSubmitForm={bindSubmitFormOne}
            setIsSaving={setIsSaving}
            isCustomeService={primaryJob && primaryJob.isCustomerService}
            zIndexCustom={3}
            offsetTop={headerHeight ?? 0}
          />
        )}
        {tab === "attachments" && (
          <Attachments
            disabled={(isFieldTechnician || (primaryJob && primaryJob.completedDate)) ? true : false}
            reloadInfomation={reloadData}
            changeSplitsEqually={changeSplitsEqually}
            updateIsEqually={updateIsEqually}
            fuData={fuData}
            openFollowUp={() => setOpenModal(true)}
            customerId={id}
            information={information}
            states={states}
            isCustomeService={primaryJob && primaryJob.isCustomerService}
            zIndexCustom={3}
            offsetTop={headerHeight ?? 0}
          />
        )}
        {tab === "split-job" && (
          <SplitJob
            disabled={(isFieldTechnician || (primaryJob && primaryJob.completedDate)) ? true : false}
            reloadInfomation={reloadData}
            changeSplitsEqually={changeSplitsEqually}
            updateIsEqually={updateIsEqually}
            fuData={fuData}
            openFollowUp={() => setOpenModal(true)}
            customerId={id}
            information={information}
            states={states}
            isCustomeService={primaryJob && primaryJob.isCustomerService}
            zIndexCustom={3}
            offsetTop={headerHeight ?? 0}
          />
        )}
        {tab === "commissions" && (
          <Commissions
            disabled={(isFieldTechnician || (primaryJob && primaryJob.completedDate)) ? true : false}
            fuData={fuData}
            setOpenModal={() => setOpenModal(true)}
            information={information}
            states={states}
            isCustomeService={primaryJob && primaryJob.isCustomerService}
            zIndexCustom={3}
            offsetTop={headerHeight ?? 0}
          />
        )}
        {tab === "completed" && (
          <Completed
            disabled={(isFieldTechnician || (primaryJob && primaryJob.completedDate)) ? true : false}
            fuData={fuData}
            setOpenModal={() => setOpenModal(true)}
            information={information}
            states={states}
            load={load}
            reloadData={reloadData}
            bindSubmitForm={bindSubmitFormOne}
            setIsSaving={setIsSaving}
            isCustomeService={primaryJob && primaryJob.isCustomerService}
            zIndexCustom={3}
            offsetTop={headerHeight ?? 0}
            invoiceGenerated={invoiceGenerated}
          />
        )}
      </div>
      <ScheduleFollowUpModal
        fuData={fuData}
        status={{
          jobSplits: information.primaryJob,
          jobStage: information.jobStage,
          scheduleDate: information.scheduleDate,
          bidAppointment: information.bidAppointment,
          completedDate: information.completedDate,
          bidCompleted: information.bidCompleted,
          id: information.id,
          description: information.description,
          companyId: information.companyId
        }}
        onCloseModal={() => {
          setOpenModal(false);
        }}
        open={openModal}
        onload={() => {
          setLoad(!load);
        }}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity={isStatus.failed ? "error" : "success"}
        >
          <Typography color="inherit" variant="h6">
            {isStatus.msg}
          </Typography>
        </Alert>
      </Snackbar>
      <ModalCopy
        openConfirm={showModalCopy}
        closeConfirm={() => setShowModalCopy(false)}
        onConfirm={redirectToNewJob}
        onScheduleJob={onScheduleJob}
      />
      <CopyJobModal
        setTitle={() => { }}
        job={information}
        setShowModalCopy={setShowModalCopy}
        setNewJob={setNewJob}
        setReloadJobs={() => { }}
        onCloseModal={() => setOpenCopyModal(false)}
        open={openCopyModal}
      />

      <Modal open={sentModalData.open}>
        <Card className={classes.styleModal}>
          <CardHeader title="The email was successfully sent" />
          <Divider />
          <CardContent style={{ padding: "20px 16px" }}>
            <Typography style={{ fontSize: "16px" }}>
              {sentModalData.to && sentModalData.to.length && (
                <span>
                  To: {sentModalData.to.join("; ")}
                  <br />
                </span>
              )}
              {sentModalData.cc && sentModalData.cc.length && (
                <span>Cc: {sentModalData.cc.join("; ")}</span>
              )}
            </Typography>
          </CardContent>
          <Divider />
          <CardActions className={classes.conButton}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                setSentModalData({ open: false });
              }}
              className={classes.button}
            >
              OK
            </Button>
          </CardActions>
        </Card>
      </Modal>

      <EmailModal
        setTitle={() => { }}
        onCloseModal={() => setOpenEmailModal(false)}
        open={openEmailModal}
        jobId={information.id}
        onGenerateInvoice={() => setInvoiceGenerated(true)}
      />
    </Page>
  );
};

CustomerJobs.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default CustomerJobs;
