
const _address = JSON.parse(sessionStorage.getItem('sw-address'));

export const address = {
    customerId: '',
    name: '',
    address1: '',
    address2: '',
    city: '',
    stateId: '',
    zipCode: '',
    taxGroupId: '',
    contacts: [
        {
            name: '',
            phone: '',
            email: '',
            isSendText: false,
            isPrimary: true
        }
    ]
}

export const initAddress = {
    customerId: '',
    name: '',
    address1: '',
    address2: '',
    city: '',
    stateId: '',
    zipCode: '',
    taxGroup: '',
    contacts: [
        {
            name: '',
            phone: '',
            email: '',
            isSendText: false,
            isPrimary: true
        }
    ]
}

export const initJob = {
    customerId: null,
    customerName: null,
    companyId: null,
    jobAddress: null,
    purchaseOrder: '',
    estimatedHours: 1,
    actualHours: '',
    jobsiteContacts: [],
    jobStage: 1,
    scheduleDate: '',
    completedDate: '',
    bidAppointment: '',
    bidCompleted: '',
    includeOnCancellation: false,
    equipment: '',
    jobEquipmentNote: null,
    jobNote: '',
    jobsiteNote: '',
    showEquipmentOnSchedule: false,
    showJobNoteOnSchedule: false,
    showJobsiteNoteOnSchedule: false,
    description: '',
    jobTemplates: [],
    displayOnPrintOutsEmails: true,
    jobItems: [
        {
            quantity: 0,
            description: '',
            price: 0,
            taxable: false,
            companyPriceItemId: null
        }
    ],
    isResceduled: true,
    dateCreated: '',
    dateModified: '',
    modifiedById: '',
    createdById: '',
    discountPercent: 0,
    discountDollars: 0,
    discountType: 0,
    taxPercent: 0
}
