const local = "http://localhost:3000/";
const dev = "https://squadware-dev-ui.azurewebsites.net/";
const demo = "https://demo.squadwareone.com/";
const prod = "https://app.squadwareone.com/";

export const baseUrl = () => {
  switch (process.env.REACT_APP_ENV) {
    case "dev": {
      return dev;
    }
    case "demo": {
      return demo;
    }
    case "prod": {
      return prod;
    }
    case "local": {
      return local;
    }
    default:
      break;
  }
};

const getUrl = type => {
  switch (process.env.REACT_APP_ENV) {
    case "dev":
    case "local":
      {
      if (type === "AUTH") {
        return "https://squadware-auth-api.azurewebsites.net/";
      }
      if (type === "USER") {
        return "https://squadware-user-api.azurewebsites.net/";
      }
      if (type === "CUSTOMER") {
        return "https://squadware-customer-api.azurewebsites.net/";
      }
      if (type === "BASE") {
        return "https://squadware-api.azurewebsites.net/";
      }
      break;
    }
    case "demo": {
      if (type === "AUTH") {
        return "https://squadware-auth-api-demo.azurewebsites.net/";
      }
      if (type === "USER") {
        return "https://squadware-user-api-demo.azurewebsites.net/";
      }
      if (type === "CUSTOMER") {
        return "https://squadware-customer-api-demo.azurewebsites.net/";
      }
      if (type === "BASE") {
        return "https://squadware-api-demo.azurewebsites.net/";
      }
      break;
    }
    case "prod": {
      if (type === "AUTH") {
        return "https://auth.squadwareone.com/";
      }
      if (type === "USER") {
        return "https://user-api.squadwareone.com/";
      }
      if (type === "CUSTOMER") {
        return "https://customer-api.squadwareone.com/";
      }
      if (type === "BASE") {
        return "https://api.squadwareone.com/";
      }
      break;
    }
    default:
      break;
  }
};

const apiConfig = {
  url: {
    AUTH_URL: getUrl("AUTH"),
    USER_URL: getUrl("USER"),
    CUSTOMER_URL: getUrl("CUSTOMER"),
    BASE_URL: getUrl("BASE"),

    SIGN_IN: "sign-in",
    EMPLOYEE_ROLES: "role/list",
    EMPLOYEE_DISABLE_USER: "disable-user",
    EMPLOYEE_UPDATE_PASSWORD: "password/update",
    EMPLOYEE_ROLE_UPDATE: "role/update",
    EMPLOYEE_SECURITY_PROFILE: "security-profile?userId=",
    EMPLOYEE_USER_ROLE_LIST: "user-role-list?userId={userId}&companyId={companyId}",

    COMPANY_CHANGE: "setcompany/{companyId}",

    AUTH_CHANGE_PASSWORD: "Password/update",
    FORGOT_PASSWORD:"forgot-password",
    RESET_PASSWORD:"reset-password",

    EMPLOYEES: "list?pageSize=24&pageNumber=",
    EMPLOYEES_ACTIVE: "list-active",
    EMPLOYEE_DELETE_PROFILE_PICTURE: "document/delete-user-image?id=",
    EMPLOYEE_ADD: "add",
    EMPLOYEE_INFO: "",
    EMPLOYEE_COMPENSATION_PUT: "Compensation/add",
    EMPLOYEE_COMPENSATION_HISTORY: "compensation/history?userId=",
    EMPLOYEE_COMPENSATION_HISTORY_UPDATE: "Compensation/update",
    EMPLOYEE_COMPENSATION_HISTORY_DELETE: "Compensation/delete?id=",
    EMPLOYEE_ADD_GET: "add",
    EMPLOYEE_ADD_PUT: "add",
    EMPLOYEE_UPDATE: "update",
    EMPLOYEE_DELETE: "delete?id=",

    EMPLOYEE_ADD_IMAGE: "document/add-image",
    EMPLOYEE_DOCUMENT_LIST: "document/list",
    EMPLOYEE_ADD_DOCUMENT: "document/add-document",
    EMPLOYEE_DELETE_DOCUMENT: "document/delete?id=",
    EMPLOYEE_AVAILABILITY_GET: "availability/get?userid=",
    EMPLOYEE_AVAILABILITY_PUT: "availability/put",
    EMPLOYEE_AVAILABILITY_UPDATE: "availability/update",
    EMPLOYEE_AVAILABILITY_DELETE: "availability/delete?id=",
    EMPLOYEE_DAYOFF_GET: "dayoff/get?userid=",
    EMPLOYEE_DAYOFF_PUT: "dayoff/put",
    EMPLOYEE_DAYOFF_UPDATE: "dayoff/update",
    EMPLOYEE_DAYOFF_DELETE: "dayoff/delete?id=",
    EMPLOYEE_ROUTE_DETAIL: "TodaysRoutes/RouteDetail/{userId}/{date}",
    EMPLOYEE_ROUTE_JOB: "TodaysRoutes/Jobs/{userId}/{date}",
    EMPLOYEE_STATES_LIST: "Address/States",

    EMPLOYEE_LOG_HOURS_EMP: "HoursLog/Employee/{EmployeeId}/{startDate}",
    EMPLOYEE_LOG_HOURS: "HoursLog/",
    EMPLOYEE_GET_LOGHOURS: "hoursLog/employee/",
    EMPLOYEE_GET_LOGHOURS_UPDATE: "HoursLog/Employee/",
    EMPLOYEE_GET_LOGHOURS_ADD: "HoursLog/Employee/",
    EMPLOYEE_GET_LOGHOURS_REMOVE: "HoursLog/Employee/{EmployeeId}/{id}",
    EMPLOYEE_GET_LOGHOURS_ALL: "HoursLog",
    EMPLOYEE_GET_LOG_HOURS_CLOCK_IN: "HoursLog/Employee/{employeeId}",
    EMPLOYEE_GET_LOGHOURS_STATUS: "HoursLog/EmployeeStatus",
    EMPLOYEE_GET_LOGHOURS_JOBSPLITID: "HoursLog/JobSplitId/{jobSplitId}",

    EMPLOYEE_TASK_TODAY: "tasks/TasksTodays",
    EMPLOYEE_TASK_UPCOMING: "tasks/TasksUpcoming",
    EMPLOYEE_TASK_COMPLETED: "tasks/TasksCompleted",
    EMPLOYEE_TASK_URGENCIES: "tasks/TaskUrgencies",
    EMPLOYEE_ADD_TASK: "tasks/AddTask",
    EMPLOYEE_UPDATE_TASK: "tasks/UpdateTask",
    EMPLOYEE_COUNT_TASK: "tasks/TaskTodaysCount",
    EMPLOYEE_DELETE_TASK: "tasks/DeleteTask",
    EMPLOYEE_COMPLETE_TASK: "tasks/TaskComplete",

    EMPLOYEE_TASK_TODAY: "tasks/TasksTodays",
    EMPLOYEE_TASK_UPCOMING: "tasks/TasksUpcoming",
    EMPLOYEE_TASK_COMPLETED: "tasks/TasksCompleted",
    EMPLOYEE_TASK_URGENCIES: "tasks/TaskUrgencies",
    EMPLOYEE_ADD_TASK: "tasks/AddTask",
    EMPLOYEE_UPDATE_TASK: "tasks/UpdateTask",
    EMPLOYEE_COUNT_TASK: "tasks/TaskTodaysCount",
    EMPLOYEE_DELETE_TASK: "tasks/DeleteTask",
    EMPLOYEE_COMPLETE_TASK: "tasks/TaskComplete",

    TODAY_ROUTE_STATUS: "TodaysRoutes/RouteJobStatus/List",
    TODAY_ROUTE_PRICE_ITEM: "TodaysRoutes/JobPriceItems/{jobId}",
    TODAY_ROUTE_EMPLOYEES: "TodaysRoutes/Users/{userId}/{date}",
    TODAY_ROUTE_STATUS_UPDATE: "TodaysRoutes/RouteJobStatus/{id}",
    TODAY_ROUTE_TEXT_CUSTOMER: "TodaysRoutes/TextStatusToCustomer/{routeJobId}",
    TODAY_ROUTE_DOCUMENT: "TodaysRoutes/Attachments/{customerId}",
    TODAY_ROUTE_DOCUMENT_NEW: "TodaysRoutes/AttachmentsNew/{customerId}",
    TODAY_ROUTE_PRICE: "Job/{jobId}/PriceItem",
    TODAY_ROUTE_PRICE_SAVE_ALL: "Job/{jobId}/PriceItem/SaveAll",
    TODAY_ROUTE_PRICE_DELETE: "Job/{jobId}/PriceItem/{itemId}",

    CUSTOMERS: "Customer/list?pageSize=24&pageNumber=",
    CUSTOMERS_GET_ENROLLMENTS: "add",
    CUSTOMERS_ADD: "add",
    CUSTOMERS_UPDATE: "update",
    CUSTOMERS_GET: "",
    CUSTOMERS_MISSING_TAX_GROUP_UPDATE: "update-tax-group/",
    CUSTOMERS_MISSING_TAX_GROUP: "missing-tax-group",
    CUSTOMERS_GET_AVATAR: "document/image?id=",
    CUSTOMERS_GET_COUNT: "notification-counts/",
    CUSTOMERS_NOTE_UPDATE: "updatenotes",
    CUSTOMERS_DOCUMENT_LIST: "document/list",
    CUSTOMERS_ADD_DOCUMENT: "document/add-document",
    CUSTOMERS_DOCUMENT_UPDATE: "document/update?id=",
    CUSTOMERS_DELETE_DOCUMENT: "document/delete?id=",
    CUSTOMERS_ADD_IMAGE: "document/add-image",
    CUSTOMERS_SENT_PDF: "document/pdf-sents?customerId={customerId}&addressId={addressId}&pdfType={pdfType}&pageSize=10&pageNumber=",
    CUSTOMERS_JOB_TEMPLATE: "Job/Template/List",
    CUSTOMERS_JOB_DETAIL: "Job/Template/Details",
    CUSTOMERS_JOB_TEMPLATE_ADD: "Job/Template",
    CUSTOMERS_JOB_TEMPLATE_DELETE: "Job/Template/{jobTemplateId}",
    CUSTOMERS_JOB_STATE: "Job/BidStage",
    CUSTOMERS_JOB_DISCOUNT_TYPE: "Job/DiscountType",
    CUSTOMERS_UPDATE_SHOW_NOTE: "updatenotes",
    CUSTOMERS_LIST_DUPLICATE: "list-duplicate",
    CUSTOMERS_LIST_DUPLICATE_ADD: "duplicate-add-new",
    CUSTOMERS_LIST_DUPLICATE_ADD_EXISTING: "duplicate-merge-existing",
    CUSTOMERS_LIST_DUPLICATE_DELETE: "duplicate",
    CUSTOMERS_POST_TO_QUICKBOOK: "Job/{jobId}/PostToQuickbooks",
    CUSTOMERS_COMPLETED_POST_TO_QUICKBOOK:
      "Job/Completed/Payment/PostToQuickbooks",
    CUSTOMERS_COMPLETED_POST_ALL_TO_QUICKBOOK:
      "Job/Completed/Payment/PostAllToQuickbooks",
    CUSTOMERS_GENERATE_QB_NAME: "qb-name",
    CUSTOMERS_UPDATE_PHONE_NUMBER: "update-phone-number",
    EMPLOYEE_LIST_ALL: "list-all",
    EMPLOYEE_LIST_ACTIVE: "list-active",
    EMPLOYEE_LIST_ACTIVE_IN_COMPANY: "list-active-in-company",
    EMPLOYEE_LIST_ACTIVE_ADMIN_COMPANY: "list-active-company-admin",
    // company
    COMPANY_INFO: "Company/",
    COMPANY: "Company?pageSize=12&pageNumber=",
    COMPANY_ADD: "Company",
    COMPANY_UPDATE: "Company/",
    ADMIN_ADD_IMAGE: "Company",
    COMPANY_COORDINATES: "Company/{companyId}/SearchCoordinates",
    COMPANY_ACTIVE: "Company/active",
    COMPANY_USER: "Company/User",
    COMPANY_CONTRACT_FORM: "Company/{id}/ContractsAndForms",
    COMPANY_SMS: "Company/{id}/Communications/SMSSettings",
    COMPANY_QUOTE: "Company/{id}/Communications/QuoteEmailSettings",
    COMPANY_FILE: "Company/{id}/Communications/PromoAttachments",
    COMPANY_PROMO_ORDER:
      "Company/{companyId}/Communications/PromoAttachmentsPosition",
    COMPANY_EQUIPMENT_LIST: "Company/{id}/Equipment",
    COMPANY_EQUIPMENT: "Company/{id}/Equipment",
    COMPANY_EQUIPMENT_SAVE_ALL: "Company/{id}/Equipment/All",
    COMPANY_INTEGRATIONS: "Company/{id}/Integrations/AskTheSeal",
    COMPANY_INTEGRATIONS_API: "ApiSiteAccess/{companyId}",
    COMPANY_INTEGRATIONS_API_ALL: "ApiSiteAccess/{companyId}/All",
    COMPANY_INTEGRATIONS_SOURCE: "AdSource/{companyId}",
    COMPANY_INTEGRATIONS_SOURCE_ALL: "AdSource/{companyId}/All",
    COMPANY_INTEGRATIONS_API_DELETE:
      "ApiSiteAccess/{companyId}/{siteAccessId}",
    COMPANY_INTEGRATIONS_SOURCE_DELETE: "AdSource/{companyId}/{adSourceId}",
    COMPANY_EMPLOYEES: "list?pageSize=200&pageNumber=",
    COMPANY_ADVERTISING_CSV: "Company/{companyId}/Advertising/CSV",
    COMPANY_PRICES_ITEM: "Company/{companyId}/PriceItem",
    COMPANY_PRICES_ITEM_ALL: "Company/{companyId}/PriceItem/All",
    COMPANY_PRICES_QUICKBOOK_LIST: "Company/{companyId}/QuickBooksItem",
    COMPANY_PRICES_OTHER_QUICKBOOKS_ITEMS: "Company/{companyId}/OtherQuickbooksItems",
    COMPANY_DELETE_FILE:
      "Company/{companyId}/Communications/PromoAttachments/{attachmentId}",
    COMPANY_ADV_ADDRESS: "Company/{companyId}/Advertising/Address",
    COMPANY_PRICE_TAX: "Company/{companyId}/Taxes",
    COMPANY_EMPLOYEES_ROUTE: "list-available",
    COMPANY_EMPLOYEES_AVAILABLE: "list-available?pageSize=200&pageNumber=",
    COMPANY_MULTI: "Company/MultiCompanyUser/{userId}",
    COMPANY_GET_GWC_FILE: "Company/{companyId}/Integrations/WebConnector",
    COMPANY_GET_ADDRESS: "Company/Address",

    COMPANY_ALL_CUSTOMER_ADDRESSES: "Address/CustomerAddresses",
    COMPANY_TRANSFER_CUSTOMERS: "Company/TransferCustomers",
    COMPANY_EMAIL_LIST: "Company/Email/All",
    COMPANY_EMAIL_ADD: "Company/Email",
    COMPANY_EMAIL_DELETE: "Company/Email/{id}",

    COMPANY_ALL_CUSTOMER_ADDRESSES: "Address/CustomerAddresses",
    COMPANY_TRANSFER_CUSTOMERS: "Company/TransferCustomers",
    LOG_HOUR_PAYROLL: "HoursLog/Payroll",
    DETAIL_PAYROLL: "HoursLog/PayrollEmployee?",

    DASHBOARD_BILL_TO_DO: "api/Dashboard/BidsToDo",
    DASHBOARD_BILL_TO_DO_TODAY: "api/Dashboard/BidsToDoToDay",
    DASHBOARD_BILL_TO_DO_PART_DUE: "api/Dashboard/BidsToDoPastDue",
    DASHBOARD_BILL_TO_DO_UP_COMMING: "api/Dashboard/BidsToDoUpcoming",
    DASHBOARD_BILL_TO_DO_PDF: "api/Dashboard/BidsToDoPdf",

    DASHBOARD_BILL_TO_DO_DISTANCES: "api/Dashboard/BidsToDoDistances",
    DASHBOARD_BILL_TO_DO_COUNT: "api/Dashboard/BidsToDoCount",
    DASHBOARD_WORD_COMPLETE_COUNT: "api/Dashboard/WorkCompleted",
    DASHBOARD_SCHEDULE_COUNT: "api/Dashboard/ScheduledAmount",
    DASHBOARD_PROFIT_COUNT: "api/Dashboard/TotalProfit",
    DASHBOARD_FOLLOW_UP_CONTACT: "Job/FollowUpContact?limit=3",
    DASHBOARD_FOLLOW_UP_CONTACT_ALL: "Job/FollowUpContact",
    DASHBOARD_FOLLOW_UP_CONTACT_TODAY: "Job/FollowUpContactToday",
    DASHBOARD_FOLLOW_UP_CONTACT_FAST_DUE: "Job/FollowUpContactPastDue",
    DASHBOARD_FOLLOW_UP_CONTACT_UP_COMING: "Job/FollowUpContactUpcoming",
    DASHBOARD_FOLLOW_UP_CONTACT_MARK_COMPLETE: "Job/MarkFollowUpComplete",
    LIST_CAN_BILL: "list-all-canbid",
    LIST_CAN_BILL_SAVE: "/Job/BidSchedule/Save",
    // Job Addresses
    CUSTOMER_JOB_ADDRESSES: "job/address?customerId=",
    CUSTOMER_JOB_ADDRESS: "job/JobsiteAddress/{addressId}",
    CUSTOMER_JOB_ADDRESSES_ADD: "job/jobsite",
    CUSTOMER_JOB_ADDRESSES_UPDATE: "job/jobsite",
    CUSTOMER_JOB_ADDRESSES_DELETE: "job/jobsite/",
    CUSTOMER_JOB_ADDRESSES_VERIFY: "Address/Verify",

    // Jobs
    CUSTOMER_JOBS: "job/address/",
    CUSTOMER_JOB: "job/",
    CUSTOMER_JOB_ADD: "job",
    CUSTOMER_JOB_UPDATE: "job",
    CUSTOMER_JOB_DELETE: "job/",
    CUSTOMER_JOB_COMBINES: "job/mergeJobs",
    CUSTOMER_JOB_SPLITS: "/split",
    CUSTOMER_JOB_SPLIT_UPDATE: "/split",
    CUSTOMER_JOB_FOLLOW_UP: "Job/{jobId}/FollowUp",
    CUSTOMER_JOB_SALESPERSON_COMMISSION: "salesperson",
    CUSTOMER_JOB_SALESPERSON_COMMISSION_GET: "job/salesperson?jobId=",
    CUSTOMER_JOB_SALESPERSON_COMMISSION_SAVE: "job/salesperson?jobId=",
    CUSTOMER_JOB_COMPLETED_PAYMENT_TYPES: "Job/Completed/PaymentTypes",
    CUSTOMER_JOB_COMPLETED_PAYMENT: "Job/Completed/Payment",
    CUSTOMER_JOB_COMPLETED_ALL_PAYMENT: "Job/Completed/Payment/All",
    CUSTOMER_JOB_SPLIT: "Job/{jobId}/Split",
    CUSTOMER_JOB_SPLIT_EMPLOYEES: "Job/{jobId}/Split/{jobSplitId}/Employees",
    CUSTOMER_JOB_SPLIT_EMPLOYEES_REMOVE: "Job/{jobId}/Split/{jobSplitId}",
    CUSTOMER_JOB_MARK_COMPLETE: "Job/MarkJobComplete",
    CUSTOMER_JOB_COMPLETION: "Job/ListCompletion",
    CUSTOMER_JOB_INVOICE: "job/CustomerInvoice",
    CUSTOMER_JOB_MARK_INVOICE_SENT: "job/MarkInvoiceSent",
    CUSTOMER_JOB_SERVICE: "job/{jobId}/CustomerService",
    CUSTOMER_JOB_COPY: "job/{jobId}/Copy",
    CUSTOMER_JOB_EMAIL: "job/{jobId}/SendEstimateEmail",
    CUSTOMER_JOB_RECURRING_JOBS: "route/RecurringRouteJobs/{recurringRouteParentId}",
    CUSTOMER_SPLIT_JOB: "Job/{jobId}/Split/Generate",
    CUSTOMER_PAY_SPLIT_TYPE: "Commission/CommissionPaySplitType",
    CUSTOMER_LABOR_SPLIT_TYPE: "Commission/CommissionLaborSplitType",
    CUSTOMER_JOB_COMMISSION_LABOR_LIST: "Commission/JobCommissionLabor/List",
    CUSTOMER_JOB_COMMISSION_LABOR: "Commission/JobCommissionLabor",
    CUSTOMER_JOB_COMMISSION_LABOR_ALL: "Commission/JobCommissionLabor/SaveAll",

    CUSTOMER_SALES_SPLIT_TYPE: "Commission/CommissionSalesSplitType",
    CUSTOMER_JOB_COMMISSION_SALE_LIST: "Commission/JobCommissionSales/List",
    CUSTOMER_JOB_COMMISSION_SALE: "Commission/JobCommissionSales",
    CUSTOMER_JOB_COMMISSION_SALE_ALL: "Commission/JobCommissionSales/SaveAll",

    CUSTOMER_UPSALES_SPLIT_TYPE: "Commission/CommissionUpsalesSplitType",
    CUSTOMER_JOB_COMMISSION_UPSALE_LIST: "Commission/JobCommissionUpsales/List",
    CUSTOMER_JOB_COMMISSION_UPSALE: "Commission/JobCommissionUpsales",
    CUSTOMER_JOB_COMMISSION_UPSALE_ALL: "Commission/JobCommissionUpsales/SaveAll",

    ADDRESS_AUTOCOMPLETE: "Address/AutoComplete",

    QUICK_BOOK_LOGS: "logs/quickbooks",

    // Routes
    ROUTE: "route/",
    ROUTE_DISTANCE: "Route/Distance",
    ROUTE_DISTANCE_MONTH: "Route/DistanceMonth",
    EQUIPMENTS_FOR_ROUTE: "equipment/list?date=",
    TO_BE_SCHEDULED_JOBS: "job/list?bidStageId=3",
    ROUTE_CLONE: "Route/{routeId}/Clone",
    RECUR_CLONE: "Route/{routeId}/Recur",
    ROUTE_NOTE: "Route/{routeId}/RouteNote",
    ROUTE_DATE: "Route/{routeId}/RouteDate",
    RECUR_ROUTE: "Route/{routeId}/RecurRoutes",
    END_RECUR_ROUTE: "Route/EndRecurRoute",
    RECUR_JOB: "Route/RecurJob",

    // Hours Log
    HOURS_LOG_EMPLOYEE_STATUS: "HoursLog/EmployeeStatus",

    JOB_INVOICE_PDF: "Job/InvoicePDF",
    JOB_GET_ESTIMATE_EMAIL: "Job/{jobId}/GetEstimateEmail",
    JOB_SEND_ESTIMATE_EMAIL: "Job/{jobId}/SendEstimateEmail",

    JOB_DOCUMENT_LIST: "document/list-job-document?JobId=",
    JOB_ADD_DOCUMENT: "document/add-job-document/",
    JOB_UPDATE_DOCUMENT: "document/update-job-document?id=",
    JOB_DELETE_DOCUMENT: "document/delete-job-document?id=",

    CUSTOMER_ADDRESS_DOCUMENT_LIST: "document/list-customeraddress-document?addressId=",
    CUSTOMER_ADDRESS_ADD_DOCUMENT: "document/add-customeraddress-document",
    CUSTOMER_ADDRESS_UPDATE_DOCUMENT: "document/update-customeraddress-document?id=",
    CUSTOMER_ADDRESS_DELETE_DOCUMENT: "document/delete-customeraddress-document?id=",

    CUSTOMER_GET_EXPIRING_CUSTOMER_DOCUMENTS: "document/expiring-customer-documents?pagenumber=",
    CUSTOMER_GET_EXPIRING_CUSTOMER_ADDRESS_DOCUMENTS: "document/expiring-customer-address-documents?pagenumber=",
    CUSTOMER_GET_EXPIRING_JOB_DOCUMENTS: "document/expiring-job-documents?pagenumber=",
    CUSTOMER_GET_EXPIRING_USER_DOCUMENTS: "document/expiring-user-documents?pagenumber=",

    ROUTE_PRINT: "Route/Print?routeDate=",
    GET_ONE_ROUTE_PRINT: "Route/PrintRoute?routeId=",
    GET_EXPANDED_ROUTES_PRINT: "Route/PrintRouteExtended?routeDate=",

    QUICK_BOOKS: "quickbooks-user",

    MULTILPLE_QUOTE_PDF: "Job/QuotePDFs",

    REPORT_CUSTOMER_INACTIVITY: "Report/customer-inactivity",
    REPORT_CUSTOMER_SERVICE: "Report/customer-service",
    REPORT_THANK_YOU_NOTE: "Report/thank-you-note",
    REPORT_CUSTOMER_COMPLETED_JOBS: "Report/customer-completed-jobs",
    REPORT_AD_REFERRAL_SOURCE: "Report/ad-referral-source",
    REPORT_GLOBAL_AD_REFERRAL_SOURCE: "Report/global-ad-referral-source",

    CUSTOMER_CITIES: 'customer-cities',

    CHECK_FEATURE: 'feature/',
    AZURE_MAPS_SUBSCRIPTION_KEY: 'feature/settings/azuremapskey',

    REPORT_GLOBAL_NICE_JOB: "Report/customer-completed-nice-jobs",

    CUSTOMERS_DELETE_DOCUMENT_FROM_EXPIRING_DOCUMENTS: "document/remove?id=",
    CUSTOMER_ADDRESS_DELETE_DOCUMENT_FROM_EXPIRING_DOCUMENTS: "document/remove-customeraddress-document?id=",
    JOB_DELETE_DOCUMENT_FROM_EXPIRING_DOCUMENTS: "document/remove-job-document?id=",
    EMPLOYEE_DELETE_DOCUMENT_FROM_EXPIRING_DOCUMENTS: "document/remove?id=",

    JOB_WORK_ORDER_PDF: "Job/WorkOrderPDF",
    DOCUMENT_SIGNATURE: "Document/signature",
    CUSTOMER_PRICE_ITEMS: "Job/PriceItems/{jobId}",

    GET_CUSTOM_FORM_COMPANY: "customForm/GetCustomFormCompany/{id}",
    GET_ACTIVE_CUSTOM_FORMS: "customForm/GetActiveCustomForms",
    GET_CUSTOM_FORM_TEMPLATES: "customForm/GetCustomFormTemplates",
    GET_CUSTOM_FORM_TEMPLATE: "customForm/GetCustomFormTemplate/{id}",
    CUSTOM_FORM_GET_USER_ANSWERS: "CustomForm/CustomFormUserAnswerQuestions",
    ACTIVE_CUSTOM_FORM_TEMPLATES: "customForm/Active/{id}",
    CUSTOM_FORM_TEMPLATES: "customForm/{id}",
    CUSTOM_FORM_USER_ANSEWR_TEMPLATES: "customForm/CustomFormUserAnswerTemplates/{jobId}",
    CUSTOM_FORM_DELETE_USER_ANSEWR: "customForm/DeleteUserAnswer",
    CUSTOM_ADD_FORM_TEMPLATES: "customForm/AddTemplate",
    CUSTOM_UPDATE_FORM_TEMPLATES: "customForm/UpdateTemplate/{id}",
    GET_CUSTOM_FORM_TYPES: "customForm/CustomFormTypes",
    GET_CUSTOM_FORM_CONTROLS_TYPES: "customForm/CustomFormControlTypes",
    CUSTOM_FORM_SAVE_USER_ANSWER: "customForm/SaveUserAnswer/{jobId}",

    PAYMENT_REQUEST_PAYMENT: "Payment/requestpayment/{encryptedPaymentInfo}",
    PAYMENT_APPLY_PAYMENT: "Payment/applypayment/{encryptedPaymentInfo}",
    PAYMENT_INVOICE_PDF: "Payment/InvoicePDF",
    PAYMENT_PAYMENT_INFO: "Payment/paymentinfo/{jobId}",
    PAYMENT_CREATE_PAYMENT: "Payment/requestpayment/{jobId}",
    PAYMENT_REQUEST_PAYMENT_URL: "Payment/requestpaymenturl/{jobId}",

    COMPANY_CELERO_INFO: "Company/Celero",

    UPDATE_QUICK_BOOKS_ONLINE: "Company/{companyId}/UseQuickbooksOnline",
    AUTHORIZE_CONNECTION: "QuickbooksOnline/GetIdentifier",
    CONNECTION_AUTHORIZE_CONNECTION: "QuickbooksOnline/Connect",
    QBO_GET_ACTIVE_TOKEN: "QuickbooksOnline/ActiveToken",
    QBO_SYSTEM_IMPORT: "QuickbooksOnline/SystemImport",
    QBO_EXPORT_CUSTOMER: "QuickbooksOnline/ExportCustomer?customerId=",
    QBO_ADDRESS_LOOKUP: "QuickbooksOnline/AddressLookup?addressId=",
    QBO_GET_TAXEXEMPT: "QuickbooksOnline/GetQBOTaxExempt",
  }
};

export default apiConfig;
